import { AddPhotoAlternate, ArrowLeft, ArrowRight, Cancel, Close, DehazeOutlined, Delete, Edit, Feed, FormatListBulletedOutlined, Groups, Home, KeyboardArrowUp, NoPhotographyOutlined, Padding, Save, SportsOutlined, Timer, Today } from '@mui/icons-material';
import { Alert, Badge, BottomNavigation, BottomNavigationAction, Box, Button, Card, CardContent, CardHeader, CardMedia, Fab, Fade, Grid, IconButton, ImageList, ImageListItem, Input, Paper, Skeleton, Snackbar, SvgIcon, Tab, Tabs, Typography, useScrollTrigger, Zoom, styled, Backdrop, CircularProgress, CardActionArea } from '@mui/material';
import React, { ChangeEventHandler, MutableRefObject } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppContext } from '../App';
import Loader from '../components/common/Loader';
import LineUpsComponent from '../components/matchDay/LineUpsComponent';
import MatchDayMatches from '../components/matchDay/MatchDayMatchesComponent';
import MatchDayMatchesDialogComponent from '../components/matchDay/MatchDayMatchesDialogComponent';
import LineUpRanking from '../components/saison/LineUpRanking';
import ManagerRanking from '../components/saison/ManagerRanking';
import TippOverview from '../components/tipp/TippOverview';
import HttpService from '../services/HttpService';
import { MatchDayModel, MatchDayState } from '../types/MatchDay';
import { AllocRole, LineUpItemChangeLog, SaisonState } from '../types/Saison';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import AlertDialog, { AlertType } from '../components/common/AlertDialog';
import moment, { duration, now } from 'moment';
import MatchDayEventsComponent from '../components/matchDay/MatchDayEventsComponent';
import TeamLeagueTableComponent from '../components/saison/TeamLeagueTableComponent';
import ImageComponent from '../components/common/ImageComponent';

const MatchDayPage = () => {
    let { id } = useParams();
    const [matchDayModel, setMatchDayModel] = React.useState<MatchDayModel>(); 
    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [value, setValue] = React.useState(0);
    const [matchTab, setMatchTab] = React.useState(0);
    const [openMatchDayMatchDialog, setOpenMatchDayMatchDialog] = React.useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState<string | undefined>("");
    const [alertType, setAlertType] = React.useState(AlertType.ERROR);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [newItemsCount, setNewItemsCount ] = React.useState(0);
    const [fadeOut, setFadeOut] = React.useState(false);
    const [imagesLoaded, setImagesLoaded] = React.useState(false);
    const [intervalId, setIntervalId] = React.useState<NodeJS.Timer>();

    // Wichtig für den Progress beim Hochladen der Bilder
    const [openBackdrop, setOpenBackdrop] = React.useState(false)

    const ctx = React.useContext(AppContext)
    const navigate = useNavigate();

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleRefresh = () => {
        setNewItemsCount(0);
        forceUpdate();
        return Promise.resolve();
    }

    React.useEffect(function() {

        window.scrollTo(0, 0);
        setFadeOut(true);

        HttpService.get('/MatchDayAPI/MatchDay', {
            params: { matchDayId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res);
                }
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)
                    
                setMatchDayModel(res.data);
                setFadeOut(false);

                clearInterval(ctx?.matchDayEventIntervalId)

                if(id) {
                    // ADD INTERVAL ############################################################################

                    const timeFrom = moment(res.data.serverDateTime.split('.')[0]).format('YYYY-MM-DD HH:mm:ss')

                    const intervalId = setInterval(() => {
                        newMatchDayMatchEventsAvailable(id, timeFrom);
                    }, 30000)

                    ctx?.setMatchDayEventIntervalId(Number(intervalId))
                }
            });  
    }, [id, reducerValue])

    const handleEditClick = () => {
        setOpenMatchDayMatchDialog(true)
    }

    const handleDeleteClick = () => {
        setOpenConfirmDeleteDialog(true)
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDeleteDialog(false);
        HttpService.get('/MatchDayAPI/DeleteMatchDay', {
            params: {matchDayId: id}
        }).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            } 
            if(res.status === 200) {
                navigate('/matchdays/' + matchDayModel?.saison.id)
            } else {
               setAlertTitle("Fehler");
               setAlertMessage(res.headers['error'])
               setOpenAlertDialog(true);
            }
        })
    }

    const handleMatchDayMatchesDialogClose = (reload:boolean) => {
        setOpenMatchDayMatchDialog(false)
        if(reload) {
            forceUpdate();
        }
    }

    const handleCapture = (event:any) => {

        setOpenBackdrop(true);

        const fileCount = event.target.files.length

        if(fileCount > 10) {
            setOpenBackdrop(false);
            setAlertMessage("Bitte nur maximal 10 Bilder gleichzeitig hochladen!")
            setAlertTitle("Fehler beim Hochladen")
            setOpenAlertDialog(true);
            return;
        }
        
        Array.from(event.target.files).forEach((ImageFile:any, index) => {
            
            var formData = new FormData();
            if(matchDayModel?.matchDay.id) {
                formData.append("MatchDayId", matchDayModel?.matchDay.id)
            }
            formData.append("file", ImageFile)

            HttpService.post('/MatchDayAPI/UploadImage',
                formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }}
            ).then(res => {
                if(index + 1 === fileCount) {
                    setOpenBackdrop(false)
                    forceUpdate();
                }
            })
        })
    };

    const handleDeleteImage = (id:string) => {
        HttpService.get('/MatchDayAPI/DeleteImage', {
            params: {MatchDayImageId: id}
        }).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            }
            if(res.status === 200) {
                forceUpdate();
            } 
        });
    }

    const handlePrevMatchDayClick = () => {
        navigate('/matchday/' + matchDayModel?.previousMatchDay.id)
    }

    const handleNextMatchDayClick = () => {
        navigate('/matchday/' + matchDayModel?.nextMatchDay.id)
    }

    const handleFadeOutComplete = () => {
        setMatchDayModel(undefined)
        setImagesLoaded(false)
    }

    let loadedImageCount = 0;

    const handleImageLoaded = () => {
        loadedImageCount++
        if(matchDayModel?.matchDayImages) {
            if(loadedImageCount >= matchDayModel?.matchDayImages.length) {
                setImagesLoaded(true)
            }
        }  
    }

    const newMatchDayMatchEventsAvailable = (matchDayId:string|undefined, timeFrom:string) => {
       
        HttpService.get('/MatchDayAPI/NewMatchDayMatchEventsAvailable', {
            params: {MatchDayId: matchDayId, timeFrom: timeFrom}
        }).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            }
            if(res.status !== 200)
                return

            if(res.data.newItems < 1)
                return

            setNewItemsCount(res.data.newItems)
        });
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;    
        return(
            <div>
                {value === index && (
                    <div>{children}</div>
                )}
            </div>
        )
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setMatchTab(newValue);
        window.scrollTo(0, 0);
    };

    matchDayModel?.matchDayImages.forEach(MatchDayImage => {
        MatchDayImage.creationTime = matchDayModel.images.find(s=>s.imageId === MatchDayImage.id)?.creationTime
    });


    return (
        <PullToRefresh onRefresh={handleRefresh} pullingContent="">
        <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            // onClick={handleClose}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
        {matchDayModel && (
            <Fade in={!fadeOut} onExited={handleFadeOutComplete}>
                <Box>
                    <Grid container>
                        {matchDayModel.previousMatchDay && (
                            <Grid item xs={1} textAlign='start' sx={{cursor: 'pointer'}} onClick={handlePrevMatchDayClick}><ArrowLeft sx={{marginTop: '0.8em', height: '1.5em', width: '1.5em'}}/></Grid>
                        )}
                        {!matchDayModel.previousMatchDay && (
                            <Grid item xs={1}></Grid>
                        )}
                        <Grid item xs={true}>
                            <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            centered
                            >
                            <Tab 
                                label={
                                    <Box>
                                        <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                        SPIELTAG
                                        </Box>
                                        <Box sx={{ display: { xs: 'inherit', md: 'none' } }}>
                                            <Box>
                                                <Typography fontSize='xx-small' position='relative' sx={{bottom: '-10px'}}>
                                                    SPIELTAG
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography fontSize='2rem' fontWeight="800" position='relative' sx={{bottom: '2px'}}>
                                                    {matchDayModel.matchDay.number}
                                                </Typography>
                                            </Box>                                       
                                        </Box>
                                    </Box>
                                } 
                                //icon={<Timer sx={{ display: { xs: 'none', md: 'inherit', height: '1.5em', width: '1.5em'} }} />} 
                                icon={<Typography fontSize='2.5rem' fontWeight="800" lineHeight={0} sx={{ display: { xs: 'none', md: 'inherit'} }}>{matchDayModel.matchDay.number}.</Typography>} 
                                iconPosition='start' 
                            />
                            <KickerTab
                            label={
                                <Box>
                                    <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                            KICKER
                                    </Box>
                                </Box>
                            }
                            icon={
                                <SvgIcon viewBox="0,0,12,24" sx={{height: '1.5em', width: '1.5em'}}>
                                    <g transform="translate(-66.46364,-100.79892)">
                                        <polygon points="0,1 0,33 8,33 8,24.5 8.4393775,24.621016 15.5,33 26,33 15.823833,21.717772 25.5,12 15.5,12 8.4393775,19.34239 8,19.5 8,1 " transform="matrix(0.7,0,0,0.7,66.463643,100.53434)"></polygon>
                                    </g>
                                </SvgIcon>
                            } iconPosition='start' />
                            <Tab /*onClick={handleRefresh}*/
                            label={
                                <Box>
                                    <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                        TICKER
                                    </Box>
                                </Box>
                            }
                            icon={
                                <Badge badgeContent={newItemsCount} color="error" >
                                    <FormatListBulletedOutlined sx={{height: '1.5em', width: '1.5em'}} />
                                </Badge>
                            }
                            iconPosition="start"
                            />
                            </Tabs>
                        </Grid>
                        {matchDayModel.nextMatchDay && (
                            <Grid item xs={1} textAlign='end' sx={{cursor: 'pointer'}} onClick={handleNextMatchDayClick}><ArrowRight sx={{marginTop: '0.8em', height: '1.5em', width: '1.5em'}} /></Grid>
                        )}
                        {!matchDayModel.nextMatchDay && (
                            <Grid item xs={1}></Grid>
                        )}
                    </Grid>
                    <Box sx={{marginTop: '5px'}}>
                        <TabPanel value={value} index={0}>
                            <Box sx={{marginBottom: 2}}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <TabPanel value={matchTab} index={0}>
                                                <MatchDayMatches 
                                                    matchDayMatches={matchDayModel.matchDayMatches}
                                                    matchDayTeams={matchDayModel.matchDayTeams}
                                                    serverDateTime={matchDayModel.serverDateTime}
                                                    teams={matchDayModel.teams}
                                                />
                                            </TabPanel>
                                            <TabPanel value={matchTab} index={1}>
                                                <TeamLeagueTableComponent 
                                                    SaisonId={matchDayModel.saison.id}
                                                    TillMatchDay={matchDayModel.matchDay.state === MatchDayState.FINISHED ? matchDayModel.matchDay.number : undefined}
                                                />
                                            </TabPanel>
                                            <Tabs value={matchTab} onChange={handleTabChange} centered>
                                                <Tab sx={{minHeight: '30px'}} icon={<SportsOutlined />} iconPosition='start' label='BEGEGNUNGEN'></Tab>
                                                <Tab sx={{minHeight: '30px'}} icon={<DehazeOutlined />} iconPosition='start' label='TABELLE'></Tab>
                                            </Tabs>
                                        </CardContent>
                                    </Card>
                            </Box>
                            {/* <Box sx={{marginBottom: 2}}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <LineUpRanking LineUps={matchDayModel.lineUps} UserSaisonAllocs={matchDayModel.userSaisonAllocs} UserSaisonAlloc={matchDayModel.userSaisonAlloc}  />
                                    </CardContent>
                                </Card>
                            </Box> */}
                            <Grid container spacing={2} sx={{marginBottom: 2}}>
                                <Grid item xs={12} xl={8}>
                                    <Card variant="outlined">
                                        {/* <CardHeader title={"WERTUNG"}/> */}
                                        <CardContent>
                                            <LineUpRanking LineUps={matchDayModel.lineUps} UserSaisonAllocs={matchDayModel.userSaisonAllocs} UserSaisonAlloc={matchDayModel.userSaisonAlloc}  />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} xl={4}>
                                    <Card variant="outlined">
                                        {/* <CardHeader title="GESAMT" /> */}
                                        <CardContent>
                                            <ManagerRanking 
                                                Managers={matchDayModel.managers} 
                                                UserSaisonAlloc={matchDayModel.userSaisonAlloc} 
                                                SaisonState={matchDayModel.saison.state}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {matchDayModel.saison.tippspiel && (
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader title="TIPPSPIEL" />
                                        <CardContent>
                                            <TippOverview 
                                                UserSaisonAllocs={matchDayModel.userSaisonAllocs} 
                                                UserSaisonAlloc={matchDayModel.userSaisonAlloc} 
                                                MatchDayMatches={matchDayModel.matchDayMatches}
                                                MatchDayTeams={matchDayModel.matchDayTeams}
                                                Teams={matchDayModel.teams}
                                                Tippers={matchDayModel.tippers}
                                                Tipps={matchDayModel.tipps}
                                                MatchDay={matchDayModel.matchDay}
                                                ServerDateTime={matchDayModel.serverDateTime}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {matchDayModel.matchDayImages.length === 0 && (
                                <Card variant="outlined" sx={{marginBottom: 2}}>
                                    <CardContent sx={{textAlign: 'center' , paddingTop: '24px'}}>
                                        <NoPhotographyOutlined sx={{fontSize: '10rem', color: '#cccccc'}} />
                                    </CardContent>
                                </Card>
                            )}
                            {matchDayModel.matchDayImages.length >= 0 && (
                                <Grid container spacing={2} sx={{marginBottom: 2}}>
                                    {matchDayModel.matchDayImages.sort((a,b) => a.creationTime && b.creationTime ? a.creationTime.localeCompare(b.creationTime) : 1).map((MatchDayImage) => (  
                                        <Grid key={MatchDayImage.id} item xs={12} xl={6}>
                                            <Card key={MatchDayImage.id} variant="outlined">
                                                <CardHeader sx={{paddingTop: '5px', paddingBottom: '5px'}} action={
                                                    matchDayModel.userSaisonAlloc.allocRole === AllocRole.owner && matchDayModel.matchDay.state === MatchDayState.OPENED && (
                                                        <IconButton onClick={() => handleDeleteImage(MatchDayImage.id)} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                                            <Close />
                                                        </IconButton>
                                                    )
                                                }>
                                                </CardHeader>
                                                <CardContent>
                                                    <a href={`${HttpService.baseUrl + '/images/upload/lineups/' + MatchDayImage.image}`} target="_blank">
                                                        <ImageComponent 
                                                            fileName={'/images/upload/lineups/' + MatchDayImage.image} 
                                                            base64Thumb={matchDayModel.images.find(s => s.imageId === MatchDayImage.id)?.base64}
                                                            width={'100%'}
                                                            />
                                                    </a>
                                                </CardContent>
                                                <CardActionArea sx={{cursor: "default"}}>
                                                    <Typography fontSize={'x-small'} marginLeft={1} marginBottom={1}>{moment(MatchDayImage.creationTime).format('DD.MM.YYYY HH:mm')}</Typography> 
                                                </CardActionArea>                              
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid> 
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <MatchDayEventsComponent 
                                MatchDayEvents={matchDayModel.matchDayEvents}
                                TeamPlayers={matchDayModel.teamPlayers}
                                Teams={matchDayModel.teams}
                                MatchDay={matchDayModel.matchDay}
                                MatchDayMatches={matchDayModel.matchDayMatches}
                                MatchDayTeams={matchDayModel.matchDayTeams}
                                LineUpItems={matchDayModel.lineUpItems}
                                UserSaisonAlloc={matchDayModel.userSaisonAlloc}
                            />
                        </TabPanel>
                    </Box>
                    <LineUpsComponent
                        lineUpItems={matchDayModel.lineUpItems}
                        lineUps={matchDayModel.lineUps}
                        teamPlayers={matchDayModel.teamPlayers}
                        teams={matchDayModel.teams}
                        userSaisonAlloc={matchDayModel.userSaisonAlloc}
                        userSaisonAllocs={matchDayModel.userSaisonAllocs}
                        matchDayTeams={matchDayModel.matchDayTeams}
                        matchDayMatches={matchDayModel.matchDayMatches}
                        matchDay={matchDayModel.matchDay}
                        serverDateTime={matchDayModel.serverDateTime}
                    />

                    <Box>
                        <ScrollTop>
                            <Fab size="small" aria-label="scroll back to top" sx={{backgroundColor:'#2e7d32', color:'#FFF', ":hover": {backgroundColor:'#2e7d32'}}}>
                                <KeyboardArrowUp />
                            </Fab>
                        </ScrollTop>
                        {matchDayModel.userSaisonAlloc.allocRole === AllocRole.owner && matchDayModel.saison.state !== SaisonState.Finished && (
                            <Box>
                                {value === 0 && (
                                    <div>
                                    {(matchDayModel.nextMatchDay === undefined || matchDayModel.nextMatchDay === null) && matchDayModel.matchDay.state === MatchDayState.OPENED && (
                                        <Fab color="primary" aria-label="add" size="small" onClick={handleDeleteClick} sx={{position: 'fixed', bottom: {xs: '166px', md: '116px'}, right: '16px'}}>
                                            <Delete />
                                        </Fab>
                                    )}
                                    <Fab color="primary" aria-label="add" size="small" onClick={handleEditClick} sx={{position: 'fixed', bottom: {xs: '116px', md: '66px'}, right: '16px'}}>
                                        <Edit />
                                    </Fab>
                                    </div>
                                )}
                                {value === 1 && matchDayModel.matchDay.state === MatchDayState.OPENED && (
                                    <Fab color="primary" aria-label="add" size="small" component="label" sx={{position: 'fixed', bottom: {xs: '116px', md: '66px'}, right: '16px'}}>
                                        <AddPhotoAlternate />
                                        <input
                                            multiple
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={handleCapture}
                                        />
                                    </Fab>
                                )}
                            </Box>
                        )}

                    </Box>
                    <MatchDayMatchesDialogComponent 
                        matchDay={matchDayModel.matchDay} 
                        matchDayMatches={matchDayModel.matchDayMatches} 
                        matchDayTeams={matchDayModel.matchDayTeams}
                        teams={matchDayModel.teams}
                        onClose={handleMatchDayMatchesDialogClose}
                        open={openMatchDayMatchDialog}
                    />
                    <ConfirmationDialog
                        open={openConfirmDeleteDialog}
                        onCancel={() => setOpenConfirmDeleteDialog(false)}
                        onNo={() => setOpenConfirmDeleteDialog(false)}
                        title='Wirklich löschen?'
                        onYes={handleConfirmDelete}
                    >
                        Möchtest du diesen Spieltag wirklich löschen?
                    </ConfirmationDialog>
                    <AlertDialog message={alertMessage} open={openAlertDialog} title={alertTitle} type={alertType} onOk={() => setOpenAlertDialog(false)} />
                </Box>

            </Fade>
        )}
        {(!matchDayModel) && (
            <Loader delay={1500}>Spieltag wird geladen...</Loader>
        )}
        </div>
        </PullToRefresh>
    )
}

   interface Props {
    window?: () => Window;
    done? : (result:boolean) => void;
    children: React.ReactElement;
  }
  
  function ScrollTop(props: Props) {

    const { children, window } = props;

    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
  
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector('#root');

      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };
  
    return (

      <Fade in={trigger} addEndListener={() => {if(props.done !== undefined) {props.done(trigger)}}}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{position: 'fixed', zIndex: 1100, bottom: {xs: '66px', md: '16px'}, right: 16 }}
        >
          {children}
        </Box>
      </Fade>
    );
  }

export default MatchDayPage

const KickerTab = styled(Tab)({
    "&.Mui-selected": {
        "& svg" : {
            color: "#C00",
            opacity: "1"
        },
        color: "#C00"
    }
});