import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HttpService from "../services/HttpService";
import { AppContext } from "../App";
import { MatchDayMatchStatistic, TeamPlayer, TeamPlayerPosition, TeamPlayerStatistics, TeamStatisticsModel } from "../types/Saison";
import Loader from "../components/common/Loader";
import {Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Fade, Grid, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import moment from "moment";
import MatchDayMatchComponent from "../components/matchDay/MatchDayMatchComponent";
import TeamLeagueTableComponent from "../components/saison/TeamLeagueTableComponent";
import PositionIcon from "../components/common/PositionIconComponent";
import TeamPlayerStatisticsDialogComponent from "../components/saison/TeamPlayerStatisticsDialogComponent";
import { teal } from "@mui/material/colors";
import ShirtComponent from "../components/common/ShirtComponent";

const TeamPage = () => {

    let { id } = useParams();

    const [ model, setModel ] = React.useState<TeamStatisticsModel>();
    const [ showAllNextGames, setShowAllNextGames ] = React.useState(false);
    const [ showTeamPlayerStatisticsDialog, setShowTeamPlayerStatisticsDialog] = React.useState(false);
    const [ selectedTeamPlayerId, setSelectedTeamPlayerId   ] = React.useState<string>();

    const ctx = React.useContext(AppContext)
    const navigate = useNavigate();

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/SaisonAPI/TeamStatistics', {
            params: { teamId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
                
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)
                    
                res.data.last10Matches.reverse();
                res.data.nextMatches.reverse();

                setModel(res.data);
            });  
    }, [id])

    const MatchTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
            ))({
            [`& .${tooltipClasses.tooltip}`]: {
                width: 280
            },
    });

    const sxProps:SxProps = {
        display: {
            xs: 'none',
            sm: 'table-cell'
        } 
    }

    return (
        <div>
            {model && (
                <Fade in>
                    <Box>
                        <Card variant="outlined">
                            <CardMedia
                                className="teamlogo-fit"
                                sx={{height: '100px'}}
                                image={HttpService.baseUrl + '/images/upload/teams/' + model.team.imageLogoPath}
                            ></CardMedia>
                             <CardContent sx={{padding: '8px'}}>
                                <Typography textAlign={'center'} variant="h4" component="div">
                                    {model.team.name}
                                </Typography>
                                <Typography textAlign={'center'} variant="h6">{model.saison.name}</Typography>
                            </CardContent>
                        </Card>
                        {model.last10Matches.length > 0 && (
                            <Card sx={{marginTop: '10px'}}>
                                <CardHeader title="LETZTE SPIELE"></CardHeader>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={4} textAlign={'right'}>
                                            <Chip
                                                avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{model.last10Matches.filter(s => s.won).length}</Avatar>}
                                                label='S'
                                                color="success"
                                            />
                                        </Grid>
                                        <Grid item xs={4} textAlign={'center'}>
                                            <Chip sx={{backgroundColor:'#cccccc'}}
                                                avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{model.last10Matches.filter(s => s.draw).length}</Avatar>}
                                                label="U"
                                            />
                                        </Grid>
                                        <Grid item xs={4} textAlign={'left'}>
                                            <Chip
                                                avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{model.last10Matches.filter(s => s.lost).length}</Avatar>}
                                                label="N"
                                                color="error"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{marginTop: '2rem'}}>
                                        {/* <Grid item xs={1.2}></Grid> */}
                                        {model.last10Matches.map((MatchDayMatchStatistic:MatchDayMatchStatistic) => 
                                            (
                                            <MatchTooltip key={MatchDayMatchStatistic.matchDayMatch.id} title={
                                                <Grid container sx={{minWidth:'4rem'}}>
                                                    <Grid item xs={2}>
                                                        <Box className='teamlogo-fit' width={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + MatchDayMatchStatistic.team1.team.imageLogoPath  + ')'}}>&nbsp;
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={true} textAlign={'right'} sx={{alignSelf:'center'}}>
                                                        <Typography variant="h6">{MatchDayMatchStatistic.team1.team.shortName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} textAlign={'center'} sx={{alignSelf:'center'}}>
                                                        <Typography variant="h5">{MatchDayMatchStatistic.team2.matchDayTeam.goalsConceded} - {MatchDayMatchStatistic.team1.matchDayTeam.goalsConceded}</Typography>
                                                    </Grid>
                                                    <Grid item xs={true} textAlign={'left'} sx={{alignSelf:'center'}}>
                                                        <Typography variant="h6">{MatchDayMatchStatistic.team2.team.shortName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Box className='teamlogo-fit' height={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + MatchDayMatchStatistic.team2.team.imageLogoPath  + ')'}}>&nbsp;
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            }>
                                                <Grid item xs={1.2} sx={{paddingLeft: '5px', cursor: 'pointer'}} onClick={() => navigate('/match/' + MatchDayMatchStatistic.matchDayMatch.id)}>
                                                    {MatchDayMatchStatistic.won && (
                                                        <Chip color="success" sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                                                    )}
                                                    {MatchDayMatchStatistic.lost && (
                                                        <Chip color="error" sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                                                    )}
                                                    {MatchDayMatchStatistic.draw && (
                                                        <Chip sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                                                    )}

                                                        <Box className='teamlogo-fit' height={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + 
                                                            (MatchDayMatchStatistic.team1.team.id !== model.team.id ? MatchDayMatchStatistic.team1.team.imageLogoPath : MatchDayMatchStatistic.team2.team.imageLogoPath) +
                                                            ')'}}>&nbsp;
                                                        </Box>
                                                </Grid>
                                            </MatchTooltip>
                                            )
                                        )}
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                        {model.nextMatches.length > 0 && (
                            <Card sx={{marginTop: '10px'}}>
                                <CardHeader title="NÄCHSTE SPIELE"></CardHeader>
                                <CardContent>
                                    {model.nextMatches.map((MatchDayMatch:MatchDayMatchStatistic, index:number) => (
                                        ((index <= 2 && !showAllNextGames) || showAllNextGames) && (
                                        <Box key={MatchDayMatch.matchDayMatch.id}>
                                            <Box marginBottom={'0.5rem'} textAlign={'center'}>{moment(MatchDayMatch.matchDayMatch.matchDate).format('dddd, DD.MM.YYYY | HH:mm').toUpperCase()}</Box>
                                            <MatchDayMatchComponent 
                                                matchDayMatch={MatchDayMatch.matchDayMatch}
                                                matchDayTeam1={MatchDayMatch.team1.matchDayTeam}
                                                matchDayTeam2={MatchDayMatch.team2.matchDayTeam}
                                                serverDateTime={model.serverDateTime}
                                                team1={MatchDayMatch.team1.team}
                                                team2={MatchDayMatch.team2.team}
                                                marginBottom="5px"
                                            />
                                        </Box>
                                        )
                                    ))}
                                </CardContent>
                                <CardActions sx={{float: 'right'}}>
                                    {!showAllNextGames && (
                                        <Button onClick={() => setShowAllNextGames(true)}>ALLE</Button>
                                    )}
                                </CardActions>
                            </Card>
                        )}
                        <Card sx={{marginTop: '10px'}}>
                            <CardHeader title="TOPSPIELER"></CardHeader>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={4} textAlign={'center'}>
                                        <Box>
                                        <Typography marginBottom={'5px'} marginTop={'5px'}>TORE</Typography>
                                        {model.topPlayersGoal.map((TeamPlayer) => (
                                            <Typography key={TeamPlayer.teamPlayerId} sx={{fontWeight: 'bold'}}>{TeamPlayer.name} ({TeamPlayer.sumGoals})</Typography>
                                        ))}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} textAlign={'center'}>
                                        <Box>
                                        <Typography marginBottom={'5px'} marginTop={'5px'}>VORARBEITEN</Typography>
                                        {model.topPlayersAssist.map((TeamPlayer) => (
                                            <Typography key={TeamPlayer.teamPlayerId} sx={{fontWeight: 'bold'}}>{TeamPlayer.name} ({TeamPlayer.sumAssists})</Typography>
                                        ))}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} textAlign={'center'}>
                                        <Box>
                                        <Typography marginBottom={'5px'} marginTop={'5px'}>BEWERTUNG</Typography>
                                        {model.topPlayersGrade.map((TeamPlayer) => (
                                            <Typography key={TeamPlayer.teamPlayerId} sx={{fontWeight: 'bold'}}>{TeamPlayer.name} ({TeamPlayer.avgGrade.toFixed(2)})</Typography>
                                        ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card sx={{marginTop: '10px'}}>
                            <CardHeader title="KADER"></CardHeader>
                            <CardContent>
                            <TableContainer  sx={{overflowX: "unset"}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>POS</TableCell>
                                            <TableCell>NAME</TableCell>
                                            <TableCell>S</TableCell>
                                            <TableCell>T</TableCell>
                                            <TableCell>V</TableCell>
                                            <TableCell>N</TableCell>
                                            <TableCell sx={sxProps}>PREIS</TableCell>
                                            <TableCell sx={sxProps}>PREIS (KICKER)</TableCell>
                                            <TableCell sx={sxProps}>MANAGER</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.teamPlayerStatistics.map((TeamPlayer) => (
                                            <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer"}}
                                                key={TeamPlayer.teamPlayerId}
                                                onClick={() => {
                                                    setSelectedTeamPlayerId(TeamPlayer.teamPlayerId) 
                                                    setShowTeamPlayerStatisticsDialog(true)
                                                    }}
                                                >
                                                <TableCell padding={'none'}>
                                                    <ShirtComponent teamPlayerPosition={TeamPlayer.position} number={TeamPlayer.number} showPosition={true} />
                                                    {/* <PositionIcon position={TeamPlayer.position} /> */}
                                                </TableCell>
                                                <TableCell>{TeamPlayer.name} {TeamPlayer.number ? "(" + TeamPlayer.number + ")" : ""}</TableCell>
                                                <TableCell>{TeamPlayer.sumSpieltage}</TableCell>
                                                <TableCell>{TeamPlayer.sumGoals}</TableCell>
                                                <TableCell>{TeamPlayer.sumAssists}</TableCell>
                                                <TableCell>{TeamPlayer.avgGrade.toFixed(2)}</TableCell>
                                                <TableCell sx={sxProps}>{TeamPlayer.price}</TableCell>
                                                <TableCell sx={sxProps}>{TeamPlayer.kickerPrice.toFixed(1)}</TableCell>
                                                <TableCell sx={sxProps}>{TeamPlayer.userName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </CardContent>
                        </Card>
                        <Card sx={{marginTop: '10px'}}>
                            <CardHeader title="FREIE SPIELER"></CardHeader>
                            <CardContent>
                            <TableContainer sx={{overflowX: "unset"}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>POS</TableCell>
                                            <TableCell>NAME</TableCell>
                                            <TableCell>PREIS (KICKER)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.teamPlayerSuggests.map((TeamPlayerSuggest) => (
                                            <TableRow 
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                key={TeamPlayerSuggest.id}
                                                >
                                                <TableCell padding={'none'}>
                                                    <ShirtComponent teamPlayerPosition={TeamPlayerSuggest.position} number={TeamPlayerSuggest.number} showPosition={true}/>
                                                    {/* <PositionIcon position={TeamPlayerSuggest.position} /> */}
                                                    {/* {(TeamPlayerSuggest.position === 1) && (
                                                        <div>TOR</div>
                                                    )}
                                                    {(TeamPlayerSuggest.position === 2) && (
                                                        <div>ABW</div>
                                                    )}
                                                    {(TeamPlayerSuggest.position === 3) && (
                                                        <div>MIT</div>
                                                    )}
                                                    {(TeamPlayerSuggest.position === 4) && (
                                                        <div>ANG</div>
                                                    )} */}
                                                </TableCell>
                                                <TableCell>{TeamPlayerSuggest.name} {TeamPlayerSuggest.number ? "(" + TeamPlayerSuggest.number + ")" : ""}</TableCell>
                                                <TableCell>{TeamPlayerSuggest.kickerPrice.toFixed(2)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </CardContent>
                        </Card>
                        <Card sx={{marginTop: '10px'}}>
                            <CardHeader title="TABELLE"></CardHeader>
                            <CardContent>
                                <TeamLeagueTableComponent
                                    SaisonId={model.saison.id}
                                    Team1Id={model.team.id}
                                />
                            </CardContent>
                        </Card>
                        {selectedTeamPlayerId && (
                            <TeamPlayerStatisticsDialogComponent 
                                teamPlayerId={selectedTeamPlayerId}
                                open={showTeamPlayerStatisticsDialog} 
                                onClose={() => setShowTeamPlayerStatisticsDialog(false)}                      
                            />
                        )}

                    </Box>
                </Fade>
            )}
            {!model && (
                <Loader delay={1500}>Verein wird geladen...</Loader>
            )}
        </div>
    )
}
export default TeamPage