import { Box, Card, CardContent, CardHeader, Fade, Grid} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import Loader from "../components/common/Loader";
import LineUpRanking from "../components/saison/LineUpRanking";
import ManagerRanking from "../components/saison/ManagerRanking";
import TippOverview from "../components/tipp/TippOverview";
import HttpService from "../services/HttpService";
import { SaisonModel, UserSaisonAlloc } from "../types/Saison";
import MatchDayMatchesComponent from "../components/matchDay/MatchDayMatchesComponent";
import EventsComponent from "../components/saison/EventsComponent";
import { useCookies } from "react-cookie";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function SaisonPage() {

    let { id, source } = useParams();
    const [saisonModel, setSaisonModel] = React.useState<SaisonModel>(); 
    const [currentMatchDayCookie, setCurrentMatchDayCookie] = useCookies(['currentMatchDay']);

    const navigate = useNavigate();
    const ctx = useContext(AppContext)

    React.useEffect(function() {
        
        window.scrollTo(0, 0);

        async function fetchSaison() {
            await HttpService.get('/SaisonAPI/Saison', {
                params: { saisonId: id }
                }).then(res => {
                    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }   

                    if(res.data.currentMatchDay) {
                        if(ctx?.account?.showActualMatchDay && source === 'home') {
                            navigate('/matchday/' + res.data.currentMatchDay.id)
                        }
                    }

                    ctx?.setCurrentMatchDay(res.data.currentMatchDay)
                    setCurrentMatchDayCookie('currentMatchDay', res.data.currentMatchDay, {path: '/' })

                    ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                    ctx?.setSaison(res.data.saison)

                    setSaisonModel(res.data);

                });
        }
        if(id) {
            fetchSaison();
        }  
    }, [id])

    function handleLastMatchDayClick() {
        navigate('/matchday/' + saisonModel?.lastMatchDay?.id)
    }

    const userSaisonAllocs:UserSaisonAlloc[] = []
    saisonModel?.managers?.forEach(function(manager) {
        userSaisonAllocs.push(manager.userSaisonAlloc)
    })

    return (
        <div>
            {saisonModel && (
                <Fade in>
                    <div>
                        {/* <a href="https://www.paypal.com/donate/?hosted_button_id=KWJ84A5PH8WT6" target="_blank">Spenden...</a> */}
                        <Grid container spacing={2} sx={{marginBottom: 2}}>
                            <Grid item xs={12} xl={saisonModel.lastMatchDay ? 5 : 12}>
                                <Box>
                                    <Card variant="outlined">
                                        <CardHeader title={saisonModel.saison.name?.toUpperCase()} />
                                        <CardContent>
                                            <ManagerRanking 
                                                Managers={saisonModel.managers} 
                                                UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                                SaisonState={saisonModel.saison.state}
                                                SaisonRanking={true}
                                            />
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            {saisonModel.lastMatchDay && (
                                <Grid item xs={12} xl={7}>
                                        <Box>
                                            <Card variant="outlined" onClick={handleLastMatchDayClick} sx={{cursor: 'pointer'}}>
                                                <CardHeader title={saisonModel.lastMatchDay.number.toString() + ". SPIELTAG"} />
                                                <CardContent>
                                                    <LineUpRanking LineUps={saisonModel.lastLineUps} UserSaisonAllocs={userSaisonAllocs} UserSaisonAlloc={saisonModel.userSaisonAlloc} />
                                                </CardContent>
                                            </Card>
                                        </Box>
                                </Grid>
                            )}
                        </Grid>


                        {/* <Box sx={{marginBottom: 2}}>
                            <Card variant="outlined">
                                <CardHeader title={saisonModel.saison.name?.toUpperCase()} />
                                <CardContent>
                                    <ManagerRanking 
                                        Managers={saisonModel.managers} 
                                        UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                        SaisonState={saisonModel.saison.state}
                                        SaisonRanking={true}
                                    />
                                </CardContent>
                            </Card>
                        </Box> */}

                        {/* {saisonModel.lastMatchDay && (
                            <Box sx={{marginBottom: 2}}>
                                <Card variant="outlined" onClick={handleLastMatchDayClick} sx={{cursor: 'pointer'}}>
                                    <CardHeader title={"SPIELTAG ".concat(saisonModel.lastMatchDay.number.toString())} />
                                    <CardContent>
                                        <LineUpRanking LineUps={saisonModel.lastLineUps} UserSaisonAllocs={userSaisonAllocs} UserSaisonAlloc={saisonModel.userSaisonAlloc} />
                                    </CardContent>
                                </Card>
                            </Box>
                        )} */}

                        {saisonModel.saison.tippspiel && saisonModel.lastMatchDay && (
                            <Box sx={{marginBottom: 2}}>
                                <Card variant="outlined">
                                    <CardHeader title={'TIPPSPIEL'} />
                                    <CardContent>
                                        <TippOverview 
                                            UserSaisonAllocs={saisonModel.userSaisonAllocs} 
                                            UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                            MatchDayMatches={saisonModel.lastMatchDayMatches}
                                            MatchDayTeams={saisonModel.lastMatchDayTeams}
                                            Teams={saisonModel.teams}
                                            Tippers={saisonModel.tippers}
                                            Tipps={saisonModel.tipps}
                                            ServerDateTime={saisonModel.serverDateTime}
                                            MatchDay={saisonModel.lastMatchDay}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        )}
                        <Box sx={{marginBottom: 2}}>
                            <Card variant="outlined">
                                <CardHeader title="TERMINE"/>
                                <CardContent>
                                    <EventsComponent SaisonId={saisonModel.saison.id} />
                                </CardContent>
                            </Card>
                        </Box>

                        {/* {saisonModel.currentMatchDay && (
                            <Box >
                                <Card variant="outlined">
                                    <CardHeader title={"SPIELTAG ".concat(saisonModel.currentMatchDay.number.toString())} onClick={() => navigate('/matchday/' + saisonModel?.currentMatchDay?.id)} sx={{cursor: 'pointer'}} />
                                    <CardContent onClick={() => navigate('/matchday/' + saisonModel?.currentMatchDay?.id)} sx={{cursor: 'pointer'}}>
                                        <MatchDayMatchesComponent 
                                            matchDayMatches={saisonModel.currentMatchDayMatches}
                                            matchDayTeams={saisonModel.currentMatchDayTeams}
                                            teams={saisonModel.teams}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        )} */}
                    </div>
                </Fade>
            )}
            {!saisonModel && (
                <Loader delay={1500}>Saison wird geladen...</Loader>
            )}
        </div>
    )
}