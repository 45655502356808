import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import HttpService from "../services/HttpService";
import { AppContext } from "../App";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Fab, styled } from "@mui/material";
import { Edit, Upload, UploadFile } from "@mui/icons-material";
import { AllocRole, Saison, SaisonState, UserSaisonAlloc } from "../types/Saison";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js";

export default function InfoPage() {
    
    let { id } = useParams();

    const [saison, setSaison] = React.useState<Saison>();
    const [userSaisonAlloc, setUserSaisonAlloc] = React.useState<UserSaisonAlloc>();
    const [openEditor, setOpenEditor] = React.useState(false)
    const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createEmpty());

    const ctx = useContext(AppContext)


    React.useEffect(function() {

        window.scrollTo(0, 0);

        if(ctx?.saison) {
            setSaison(ctx.saison);
            setUserSaisonAlloc(ctx.userSaisonAlloc);

            if(ctx.saison.memo) {
                try {
                    setEditorState(EditorState.createWithContent(
                        convertFromRaw(JSON.parse(ctx.saison.memo))
                    ))
                } catch {
                    
                }
            }

        } else {
            HttpService.get('/SaisonAPI/SaisonMemo', {
                params: { saisonId: id }
                }).then(res => {
                    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                        console.log(res.data);
                    }   
                    ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                    ctx?.setSaison(res.data.saison);
                    setSaison(res.data.saison)
                    setUserSaisonAlloc(res.data.userSaisonAlloc)

                    if(res.data.saison.memo) {
                        try {
                            setEditorState(EditorState.createWithContent(
                                convertFromRaw(JSON.parse(res.data.saison.memo))
                            ))
                        } catch {
                            
                        }
                    }
                });
        }
      
    }, [id])

    const handleEditorOk = () => {
        HttpService.post("/SaisonAPI/SaisonMemo", {
            id: saison?.id,
            memo: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
          }).then(res => {
            if(res.status === 200) {
                setOpenEditor(false);
            } 
          })
    }

    // const handleDocument = (event:any) => {

    //     const fileCount = event.target.files.length
    
    //     Array.from(event.target.files).forEach((File:any, index) => {
            
    //         var formData = new FormData();
    //         if(saison?.id) {
    //             formData.append("SaisonId", saison?.id)
    //         }
    //         formData.append("file", File)

    //         HttpService.post('/SaisonAPI/Document',
    //             formData, {
    //                 headers: {
    //                 'Content-Type': 'multipart/form-data'
    //                 }}
    //         ).then(res => {
    //             if(index + 1 === fileCount) {
    //                 forceUpdate();
    //             }
    //         })
    //     })
    // };

    return (
        <Box>
            <Card>
                <CardHeader title="INFORMATIONEN" />
                <CardContent>
                    {saison && (
                        <Editor 
                            readOnly={true}
                            toolbarHidden={true}
                            editorState={EditorState.createWithContent(editorState.getCurrentContent())}
                        />
                    )}
                </CardContent>
            </Card>
            {userSaisonAlloc?.allocRole === AllocRole.owner && (
                <Box>
                    <Fab color="primary" aria-label="add" size="small" onClick={() => setOpenEditor(true)} sx={{position: 'fixed',  bottom: (ctx?.saison ? {xs: '66px', md: '16px'} : '16px'), right: '16px'}}>
                        <Edit />
                    </Fab>
                    {/* <Fab color="primary" aria-label="add" size="small" component="label" sx={{position: 'fixed',  bottom: (ctx?.saison ? {xs: '116px', md: '66px'} : '16px'), right: '16px'}}>
                        <UploadFile />
                        <input
                            type="file"
                            accept="image/*,application/pdf"
                            hidden
                            onChange={handleDocument}
                        />
                    </Fab> */}
                </Box>
            )}
            <EditorDialog 
                open={openEditor}
                onAbort={() => setOpenEditor(false)}
                onOk={handleEditorOk}
                editorState={editorState}
                onStateChange={(editorState) => setEditorState(editorState)}
            />
        </Box>
        
    )
}

const EditorDialog = (props: {open:boolean, onAbort():void, onOk():void, editorState:EditorState, onStateChange(editorState:EditorState):void} ) => {
    return (
        <CustomDialog open={props.open}>
            <DialogTitle>INFOS</DialogTitle>
            <DialogContent>
                <Editor 
                    editorStyle={{ height: '200px' }}
                    editorState={props.editorState} 
                    onEditorStateChange={props.onStateChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onAbort}>ABBRECHEN</Button>
                <Button onClick={props.onOk}>ÜBERNEHMEN</Button>
            </DialogActions>
        </CustomDialog>
    )
}

const CustomDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        minHeight: '500px',
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))