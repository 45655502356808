import { ArrowDropDown, ArrowDropUp, ArrowRight, Edit } from '@mui/icons-material';
import { Alert, Badge, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { MatchDay, MatchDayMatch, MatchDayState, MatchDayTeam } from '../../types/MatchDay';
import { Team, UserSaisonAlloc } from '../../types/Saison';
import { Tipp, Tipper } from '../../types/Tipp';
import EditTippsDialogComponent from './EditTippsDialogComponent';
import React from 'react';
import moment from 'moment';
import TipperStatisticsDialogComponent from '../saison/TipperStatisticsDialogComponent';

function TippOverview(props:{
    Tippers:Tipper[], 
    Tipps:Tipp[], 
    UserSaisonAllocs:UserSaisonAlloc[], 
    UserSaisonAlloc:UserSaisonAlloc,
    MatchDayMatches:MatchDayMatch[],
    MatchDayTeams:MatchDayTeam[],
    MatchDay:MatchDay,
    Teams:Team[],
    ServerDateTime:string
    }) {

    const [openEditDialog, setOpenEditDialog] = React.useState(false)
    const [openStatisticsDialog, setOpenStatisticsDialog] = React.useState(false)
    const [selectedTipper, setSelectedTipper ] = React.useState("");
    const [error, setError] = React.useState("");

    if(!props.Tippers) {
        return (<Box>No Tippers</Box>)
    }

    props.Tippers.sort(function(a,b:Tipper){return a.points < b.points?1 : -1 })

    var _Number = 0;
    var _Rang = 0;
    var _Points = 0;
    var _PreNumber = 0;
    var _PreRang = 0;
    var _PrePoints = 0;

    props.Tippers.forEach((tipper) => {
        _Number++
        if(_Points !== tipper.points)
            _Rang = _Number

        _Points = tipper.points
        tipper.rang = _Rang

        tipper.matchDayPoints = 0;
        props.Tipps.filter(m => m.userSaisonAllocId === tipper.userSaisonAllocId).forEach((tipp) => {
            tipper.matchDayPoints += tipp.points;
        })
        tipper.prePoints = tipper.points - tipper.matchDayPoints

        if(tipper.userSaisonAllocId === props.UserSaisonAlloc.id) {
            props.MatchDayMatches.forEach((MatchDayMatch) => {
                if(moment(MatchDayMatch.matchDate) < moment().add(6, 'hours') && moment(MatchDayMatch.matchDate) > moment()) {
                    if(!props.Tipps.find(s => s.matchDayMatchId === MatchDayMatch.id)) {
                        if(error.length === 0) {
                            setError("Es wurden noch nicht alle Tipps für die in Kürze anstehenden Spiele abgegeben.")
                        }       
                    }   
                }
            })
        }
    })

    props.Tippers.sort(function(a,b:Tipper){return a.prePoints < b.prePoints?1 : -1 })

    var _MaxMatchDayPoints = 0;

    props.Tippers.forEach((tipper) => {
        _PreNumber++
        if(_PrePoints !== tipper.prePoints)
            _PreRang = _PreNumber

        _PrePoints = tipper.prePoints
        tipper.preRang = _PreRang

        var _MatchDayPoints = props.Tipps.filter(m => m.userSaisonAllocId === tipper.userSaisonAllocId).reduce((sum, current) => sum + current.points, 0)
        
        if(_MatchDayPoints > _MaxMatchDayPoints) {
            _MaxMatchDayPoints = _MatchDayPoints;
        }
    })

    props.Tippers.sort(function(a,b:Tipper)
    {
        if(a.points < b.points) {
            return 1
        } else if(a.points > b.points) {
            return -1
        }

        if(a.userSaisonAllocId.localeCompare(b.userSaisonAllocId)) {
            return 1
        } else {
            return -1
        }
    })

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
    }

    const handleEditDialogOpen = () => {
        setOpenEditDialog(true)
    }

    const handleStatisticsDialogClose = () => {
        setOpenStatisticsDialog(false);
    }

    const handleStatisticsDialogOpen = (userSaisonAllocId:string) => {
        setSelectedTipper(userSaisonAllocId)
        setOpenStatisticsDialog(true)
    }

    return (
        <TableContainer>
            {error.length > 0 && (
                <Alert severity="error" sx={{marginBottom: '10px'}}><Typography fontSize={'medium'}>{error}</Typography></Alert>
            )}
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{verticalAlign: 'bottom'}} colSpan={2}>PL.</TableCell>
                        <TableCell sx={{verticalAlign: 'bottom'}}>NAME</TableCell>
                        {props.MatchDay.state === MatchDayState.OPENED && (
                            <TableCell/>
                        )}
                        {props.MatchDayMatches.map((matchDayMatch) => (
                            <TableCell key={matchDayMatch.id} align="center" sx={{paddingRight: '1rem'}}>
                                <Box>{props.Teams.find(m => m.id === props.MatchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId1)?.teamId)?.shortName}</Box>
                                <Box sx={{whiteSpace: 'nowrap'}}>
                                    {moment(matchDayMatch.matchDate) >= moment(props.ServerDateTime) ? '- : -' : (props.MatchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId2)?.goalsConceded + ' : ' + props.MatchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId1)?.goalsConceded)}
                                </Box>
                                <Box>{props.Teams.find(m => m.id === props.MatchDayTeams.find(m => m.id === matchDayMatch.matchDayTeamsId2)?.teamId)?.shortName}</Box>
                            </TableCell>
                        ))}
                        <TableCell align='right' sx={{verticalAlign: 'bottom'}}>PKT</TableCell>
                        <TableCell align='right' sx={{verticalAlign: 'bottom'}}>GES</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.Tippers.map((tipper) => (
                        <TableRow
                            key={tipper.userSaisonAllocId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px'}}
                            className={tipper.userSaisonAllocId === props.UserSaisonAlloc.id ? 'me-self' : ''}
                        >
                            <TableCell sx={{ width: 15 }}>
                                    {tipper.rang}
                            </TableCell>
                            <TableCell sx={{ width: 15, paddingTop: 0, paddingBottom: 0 }}>
                                    {tipper.rang < tipper.preRang && props.MatchDay.number > 1 && (
                                        <ArrowDropUp color="success" style={{verticalAlign: 'bottom'}}/>
                                    )}
                                    {tipper.rang > tipper.preRang && props.MatchDay.number > 1 && (
                                        <ArrowDropDown sx={{ color: red[500] }} />
                                    )}
                                    {(tipper.rang === tipper.preRang || props.MatchDay.number < 2) && (
                                        <ArrowRight />
                                    )}
                            </TableCell>
                            <TableCell
                                sx={{cursor: "pointer"}}
                                onClick={() => {
                                    handleStatisticsDialogOpen(tipper.userSaisonAllocId) 
                                }}
                            >
                                {props.UserSaisonAllocs.find(m => m.id === tipper.userSaisonAllocId)?.playerName}   

                            </TableCell>
                            {props.MatchDay.state === MatchDayState.OPENED && (
                                <TableCell>
                                {tipper.userSaisonAllocId === props.UserSaisonAlloc.id && (
                                        <Edit sx={{cursor: 'pointer'}} onClick={handleEditDialogOpen} />
                                )}
                                </TableCell>
                            )}
                            {props.MatchDayMatches.map((matchDayMatch) => (
                                <TableCell key={matchDayMatch.id} align="center" sx={{paddingRight: '1rem'}}>
                                    {props.Tipps.filter(m => m.userSaisonAllocId === tipper.userSaisonAllocId && m.matchDayMatchId === matchDayMatch.id).map((tipp) => (
                                        <Badge key={tipp.matchDayMatchId} className={`tippBadge ${tipp.points === 2 ? 'blue' : ''} ${tipp.points === 3 ? 'sienna' : ''} ${tipp.points === 4 ? 'green' : ''}`} badgeContent={tipp.points}>
                                            {tipp.team1GoalsTipp >= 0 && tipp.team2GoalsTipp >= 0 && (
                                                <Box sx={{whiteSpace: 'nowrap'}}>
                                                    {tipp.team1GoalsTipp} : {tipp.team2GoalsTipp}
                                                </Box>
                                            )}
                                            {(tipp.team1GoalsTipp < 0 || tipp.team2GoalsTipp < 0) && (
                                                <Box sx={{whiteSpace: 'nowrap'}}>
                                                    - : -
                                                </Box>
                                            )}
                                        </Badge>
                                    ))}
                                </TableCell>
                            ))}
                            <TableCell align='right' className={props.Tipps.filter(m => m.userSaisonAllocId === tipper.userSaisonAllocId).reduce((sum, current) => sum + current.points, 0) === _MaxMatchDayPoints && _MaxMatchDayPoints != 0 ? 'max-matchday-tipp-points' : ''}>
                                {props.Tipps.filter(m => m.userSaisonAllocId === tipper.userSaisonAllocId).reduce((sum, current) => sum + current.points, 0)}
                            </TableCell>
                            <TableCell align='right'>{tipper.points}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <EditTippsDialogComponent 
                matchDayMatches={props.MatchDayMatches} 
                matchDayTeams={props.MatchDayTeams} 
                userSaisonAlloc={props.UserSaisonAlloc} 
                tipps={props.Tipps} 
                open={openEditDialog} 
                teams={props.Teams}
                serverDateTime={props.ServerDateTime}
                onClose={handleEditDialogClose} />
            <TipperStatisticsDialogComponent 
                open={openStatisticsDialog} 
                userSaisonAllocId={selectedTipper} 
                onClose={handleStatisticsDialogClose}                
            />
        </TableContainer>
    )
}

export default TippOverview