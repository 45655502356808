import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Input, LinearProgress, MenuItem, Select, SelectChangeEvent, styled, Switch, Typography  } from "@mui/material";
import moment from "moment";
import React, { ChangeEvent } from "react";
import HttpService from "../../services/HttpService";
import { MatchDayMatch, MatchDayTeam } from "../../types/MatchDay";
import { LineUp, LineUpItem, Team, TeamPlayer, TeamPlayerPosition, UserSaisonAlloc } from "../../types/Saison";
import Spielfeld from "../../res/images/Spielfeld.png"
import { ExpandCircleDown, ModelTraining } from "@mui/icons-material";
import { Fade } from "react-awesome-reveal";
import DialogProgressComponent from "../common/DialogProgressComponent";
import PositionIcon from "../common/PositionIconComponent";
import SoccerBall from "../../res/images/SoccerBall.png"
import ShirtComponent from "../common/ShirtComponent";

export interface Props {
    open: boolean;
    lineUp?: LineUp;
    lineUpItems: LineUpItem[];
    teamPlayers: TeamPlayer[];
    matchDayMatches: MatchDayMatch[];
    matchDayTeams: MatchDayTeam[];
    teams: Team[];
    userSaisonAlloc:UserSaisonAlloc
    serverDateTime:string
    onClose: () => void;
}

function LineUpItemDialogComponent(props: Props){

    const { open, onClose } = props;
    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [editedLineUpItems, setEditedLineUpItems] = React.useState(new Array<LineUpItem>());
    const [error, setError] = React.useState("");
    const [saveable, setIsSaveable] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    props.lineUpItems.forEach((lineUpItem) => {

        var TeamPlayer = props.teamPlayers.find(tp => tp.id === lineUpItem.teamPlayerId)

        if(TeamPlayer !== null) {
            var Team = props.teams.find(t => t.id === TeamPlayer?.teamId)
            var MatchDayTeam = props.matchDayTeams.find(mdt => mdt.teamId === Team?.id)
            var Match = props.matchDayMatches.find(m => m.matchDayTeamsId1 === MatchDayTeam?.id || m.matchDayTeamsId2 === MatchDayTeam?.id)
            if(moment(Match?.matchDate).isAfter(moment(props.serverDateTime.split(".")[0]))) {
                lineUpItem.changeable = true
            }
        } else {
            lineUpItem.changeable = true
        }
    })

    const handleOk = () => {
        setLoading(true)
        HttpService.post('/MatchDayAPI/LineUpItems', editedLineUpItems).then(res => {

            if(res.status === 200) {

                setEditedLineUpItems(new Array<LineUpItem>())
                setIsSaveable(false)
                setError("");
                onClose();

                editedLineUpItems.forEach((editedLineUpItem) => {
                    var updatedLineUpItem = props.lineUpItems.find(lui => lui.id === editedLineUpItem.id);
                    if(updatedLineUpItem !== undefined) {
                        updatedLineUpItem.teamPlayerId = editedLineUpItem.teamPlayerId;
                        updatedLineUpItem.substitute = editedLineUpItem.substitute;
                        updatedLineUpItem.notPlayed = editedLineUpItem.notPlayed;
                    }
                })

            } else if(res.status === 400) {
                var errorMessage = res.headers["error"];
                if(errorMessage != undefined) {
                    setError(errorMessage)
                }    
            }
            setLoading(false)
        })
    }
    
    const handleCancel = () => {
        setEditedLineUpItems(new Array<LineUpItem>())
        setIsSaveable(false);
        setError("");
        onClose();
    }

    const handleTeamPlayerChange = (event: SelectChangeEvent) => {
        var selectedLineUpItem = editedLineUpItems.find(lui => lui.id === event.target.name)
    
        if (selectedLineUpItem !== undefined) {
            selectedLineUpItem.teamPlayerId = event.target.value;
        } else {
            var lineUpItem = props.lineUpItems.find(lui => lui.id === event.target.name)
            if(lineUpItem != undefined) {
                const editedLineUpItem = copyLineUpItem(lineUpItem)
                editedLineUpItem.teamPlayerId = event.target.value
                editedLineUpItems.push(editedLineUpItem)
            }
        }

        setIsSaveable(isSaveable(props.lineUpItems, editedLineUpItems));
        forceUpdate();
    }

    const handleSwitchChanged = (event: ChangeEvent<HTMLInputElement>, checked:boolean) => {

        var selectedLineUpItem = editedLineUpItems.find(lui => lui.id === event.target.dataset.id);

        if (selectedLineUpItem !== undefined) {
            if(event.target.dataset.type === 'notPlayed') {
                selectedLineUpItem.notPlayed = checked;
            } else {
                selectedLineUpItem.substitute = checked;
            }
        } else {
            var lineUpItem = props.lineUpItems.find(lui => lui.id === event.target.dataset.id)
            if(lineUpItem != undefined) {
                const editedLineUpItem = copyLineUpItem(lineUpItem)
                editedLineUpItems.push(editedLineUpItem)
                if(event.target.dataset.type === 'notPlayed') {
                    editedLineUpItem.notPlayed = checked
                } else {
                    editedLineUpItem.substitute = checked
                }
            }
        }
        setIsSaveable(isSaveable(props.lineUpItems, editedLineUpItems));
        forceUpdate();
    }

    return (
        <LineUpDialog fullWidth={true} open={open}>
            <DialogTitle>

            <LineUpAccordion sx={{boxShadow: 'none'}}>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{padding: 0, content: {sx:{ marginTop: '50px'}}}}
                    
                >
                    <Typography variant="h6">AUFSTELLUNG</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: 0}}>

                <Grid container sx={{
                    mb: 1,
                    backgroundImage: `url(${Spielfeld})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '150px'
                }}>
                    <Grid item xs={12}> 
                        <Grid container sx={{pt:4}} justifyContent={'center'}>
                        {
                            lineUpItems(props.lineUpItems, editedLineUpItems).filter(s => s.teamPlayerId).map((LineUpItem) => {
                                const TeamPlayer = props.teamPlayers.find(s => s.id === LineUpItem.teamPlayerId && s.position === TeamPlayerPosition.striker)
                                if(TeamPlayer) {
                                    return (
                                            // <Grid item xs={4} sx={{pb:4}} textAlign="center" key={LineUpItem.id}>
                                            //     <Badge sx={{width: '100%', backgroundColor: 'white'}} badgeContent={<img width={'16px'} src={SoccerBall} />}>
                                            //         <Box sx={{width: '100%'}} className="playerInField">{TeamPlayer.name}</Box>
                                            //     </Badge>
                                            // </Grid>
                                        
                                        <Grid item xs={4} sx={{pb:4}} textAlign="center" key={LineUpItem.id}>
                                            <Box className="playerInField">{TeamPlayer.name}</Box>
                                        </Grid>
                                    )
                                }
                            })
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}> 
                        <Grid container justifyContent={'center'}>
                        {
                            lineUpItems(props.lineUpItems, editedLineUpItems).filter(s => s.teamPlayerId).map((LineUpItem) => {
                                const TeamPlayer = props.teamPlayers.find(s => s.id === LineUpItem.teamPlayerId && s.position === TeamPlayerPosition.midfielder)
                                if(TeamPlayer) {
                                    return (
                                        <Grid item xs={4} sx={{pb:4}} textAlign="center" key={LineUpItem.id}>
                                            <Box className="playerInField">{TeamPlayer.name}</Box>
                                        </Grid>
                                    )
                                }
                            })
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}> 
                        <Grid container justifyContent={'center'}>
                        {
                            lineUpItems(props.lineUpItems, editedLineUpItems).filter(s => s.teamPlayerId).map((LineUpItem) => {
                                const TeamPlayer = props.teamPlayers.find(s => s.id === LineUpItem.teamPlayerId && s.position === TeamPlayerPosition.defender)
                                if(TeamPlayer) {
                                    return (
                                        <Grid item xs={4} sx={{pb:4}} textAlign="center" key={LineUpItem.id}>
                                            <Box className="playerInField">{TeamPlayer.name}</Box>
                                        </Grid>
                                    )
                                }
                            })
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}> 
                        <Grid container justifyContent={'center'}>
                        {
                            lineUpItems(props.lineUpItems, editedLineUpItems).filter(s => s.teamPlayerId).map((LineUpItem) => {
                                const TeamPlayer = props.teamPlayers.find(s => s.id === LineUpItem.teamPlayerId && s.position === TeamPlayerPosition.goalkeeper)
                                if(TeamPlayer) {
                                    return (
                                        <Grid sx={{pb:4}} item xs={4} textAlign="center" key={LineUpItem.id}>
                                            <Box className="playerInField">{TeamPlayer.name}</Box>
                                        </Grid>
                                    )
                                }
                            })
                        }
                        </Grid>
                    </Grid>
                </Grid>
                </AccordionDetails>
            </LineUpAccordion>
            </DialogTitle>
                {loading && (
                    <DialogProgressComponent delay={1000} />
                )}
                <DialogContent dividers>
                    <Alert variant="filled" severity="error" sx={{mb: 1, display: `${error.length === 0 ? 'none' : 'flex'}`}}>
                        {error}
                    </Alert>
                    {props.lineUpItems.map((lineUpItem) => (
                        <Grid container key={lineUpItem.id}>
                            <Grid item xs={8} overflow={'visible'}>
                                <FormControl  sx={{ minWidth: 'calc(100%)', mb: 1, width: '100%' }} size="small">
                                    <Select
                                        name={lineUpItem.id}
                                        value={
                                            (editedLineUpItems.find(s => s.id === lineUpItem.id)?.teamPlayerId ? 
                                                editedLineUpItems.find(s => s.id === lineUpItem.id)?.teamPlayerId : 
                                                (lineUpItem.teamPlayerId ? lineUpItem.teamPlayerId : ''))
                                        }
                                        onChange={handleTeamPlayerChange}
                                        disabled={!lineUpItem.changeable}
                                    >
                                        {props.teamPlayers.filter(tp => (tp.active || !lineUpItem.changeable) && tp.name.toUpperCase() != 'JOKER').map((teamPlayer) => (
                                            <MenuItem 
                                            key={teamPlayer.id}
                                            value={teamPlayer.id}
                                            >
                                                <Grid container wrap="nowrap">
                                                    <Grid item marginRight={'5px'}>
                                                        {/* <ShirtComponent number={teamPlayer.number} teamPlayerPosition={teamPlayer.position} height="24px" width="24px"/> */}
                                                        <PositionIcon position={teamPlayer.position}/>
                                                    </Grid>
                                                    <Grid item textOverflow={'ellipsis'} overflow={'hidden'}>
                                                        {
                                                            // teamPlayer.position == TeamPlayerPosition.goalkeeper ? "(T) " :
                                                            // teamPlayer.position == TeamPlayerPosition.defender ? "(A) " :
                                                            // teamPlayer.position == TeamPlayerPosition.midfielder ? "(M) " :
                                                            // teamPlayer.position == TeamPlayerPosition.striker ? "(S) "  : "" 
                                                        } {teamPlayer.name} ({teamPlayer.number})
                                                    </Grid>
                                                </Grid>
                                                
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <BKSwitch 
                                    inputProps={{
                                        //@ts-ignore
                                        'data-id': lineUpItem.id,
                                        'data-type': 'substitute'
                                    }}
                                    disabled={
                                        !lineUpItem.changeable || 
                                        (editedLineUpItems.find(s => s.id === lineUpItem.id)?.teamPlayerId ? 
                                            false : (lineUpItem.teamPlayerId ? false : true))
                                    }                         
                                    className="bk-switch" 
                                    checked={
                                        (editedLineUpItems.find(s => s.id === lineUpItem.id) ? 
                                            editedLineUpItems.find(s => s.id === lineUpItem.id)?.substitute : lineUpItem.substitute)
                                    }
                                    onChange={handleSwitchChanged}
                                    />
                            </Grid>
                            <Grid item xs={2} textAlign="center">
                                <BKSwitch 
                                    inputProps={{
                                        //@ts-ignore
                                        'data-id': lineUpItem.id,
                                        'data-type': 'notPlayed'
                                    }}
                                    disabled={
                                        !lineUpItem.changeable || 
                                        (editedLineUpItems.find(s => s.id === lineUpItem.id)?.teamPlayerId ? 
                                            false : (lineUpItem.teamPlayerId ? false : true))
                                    } 
                                    className="f-switch" 
                                    checked = {
                                        (editedLineUpItems.find(s => s.id === lineUpItem.id) ? 
                                        editedLineUpItems.find(s => s.id === lineUpItem.id)?.notPlayed : lineUpItem.notPlayed)
                                    }
                                    onChange={handleSwitchChanged}/>
                                </Grid>
                        </Grid>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                    <Button onClick={handleOk} disabled={!saveable || loading}>ÜBERNEHMEN</Button>
                </DialogActions>
        </LineUpDialog>
    )
}

let copyLineUpItem = (original:LineUpItem) : LineUpItem => {

    return {
        id: original.id,
        assists: original.assists,
        card: original.card,
        goals: original.goals,
        grade: original.grade,
        lineUpId: original.lineUpId,
        notPlayed: original.notPlayed,
        playerOfDay: original.playerOfDay,
        playerOfMatch: original.playerOfMatch,
        points: original.points,
        substitute: original.substitute,
        teamOfDay: original.teamOfDay,
        teamPlayerId: original.teamPlayerId,
        changeable: original.changeable
    }
}

const isSaveable = (originalLineUpItem:LineUpItem[], editedLineUpItems:LineUpItem[]) : boolean => {

    var result = true;

    if(originalLineUpItem.filter(s => s.teamPlayerId).length === originalLineUpItem.length) {
        return result;
    }

    originalLineUpItem.forEach((lineUpItem) => {
        if(!lineUpItem.teamPlayerId) {
            if(!editedLineUpItems.find(s => s.id === lineUpItem.id)) {
                result = false;
            }
        }
    })

    return result;
}


const lineUpItems = (originalLineUpItem:LineUpItem[], editedLineUpItems:LineUpItem[]) : Array<LineUpItem> => {

    var result = new Array<LineUpItem>();

    originalLineUpItem.forEach((lineUpItem) => {
        const editedLineUpItem = editedLineUpItems.find(s => s.id === lineUpItem.id)
        if(editedLineUpItem) {
            result.push(editedLineUpItem)
        } else {
            result.push(lineUpItem)
        }
    })
    
    return result;
}

const LineUpDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))

const BKSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {   
        left: 12,
      },
      '&:after': {
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const LineUpAccordion = styled(Accordion)(({theme}) => {
    return {
        '.MuiAccordionSummary-root': {minHeight: 0}, // this apply to Summary
        '.MuiAccordionSummary-content': {margin: 0},
        '.MuiAccordionSummary-content.Mui-expanded': {margin: 0, minHeight: 0},
        '.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {minHeight: 0}
    }
  })

export default LineUpItemDialogComponent