import React from "react";
import HttpService from "../../services/HttpService";
import { Box, SxProps, Theme } from "@mui/material";

export default function ImageComponent(props: 
    { 
        base64Thumb?: string 
        fileName: string
        width?: string|number
        height?: string|number
        maxWidth?: string|number
        maxHeight?: string|number
        objectFit?:string
        sx?: SxProps<Theme>
        className?: string
        format?:string
    }) {

    const [loaded, setLoaded] = React.useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    }
    
    return (
        <Box className={props.className} sx={props.sx} display={'flex'}>
            {props.base64Thumb && (
            <img width={props.width} height={props.height} style={loaded ? {display: 'flex', maxHeight: props.maxHeight, maxWidth: props.maxWidth, objectFit:'contain'} : {display: 'none'}}
                src={`${HttpService.baseUrl + props.fileName}`}
                srcSet={`${HttpService.baseUrl + props.fileName}`}
                onLoad={handleImageLoaded}
            />
            )}
            {props.base64Thumb && (
                <img width={props.width} height={props.height} style={loaded ? {display: 'none'} : {display: 'flex', maxHeight: props.maxHeight, maxWidth: props.maxWidth, objectFit: 'contain'}}
                    src={`data:image/${props.format ? props.format : 'jpeg'};base64,${props.base64Thumb}`}
            />
            )}
            {!props.base64Thumb && (
            <img width={props.width} height={props.height} style={{display: 'flex', maxHeight: props.maxHeight, maxWidth: props.maxWidth, objectFit:'contain'}}
                src={`${HttpService.baseUrl + props.fileName}`}
                srcSet={`${HttpService.baseUrl + props.fileName}`}
            />
            )}
        </Box>
    )
}