import { SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LineUp, UserSaisonAlloc } from "../../types/Saison";

export default function LineUpRanking(props: {LineUps?: LineUp[], UserSaisonAllocs?:UserSaisonAlloc[], UserSaisonAlloc: UserSaisonAlloc}) {
    const {LineUps} = props;

    if(LineUps) {
        LineUps.sort(function(a, b:LineUp) {return a.id < b.id?1 : -1 })

        LineUps.sort(function(a,b:LineUp){return a.placePoints < b.placePoints?1 : -1 })

        var _LineUpRang = 0;
        var _LineUpRowNumber = 0;
        var _LineUpPoints = 0;

        LineUps.forEach(function(LineUp:LineUp) {
            _LineUpRowNumber++
            if(_LineUpPoints !== LineUp.placePoints) {
                _LineUpRang = _LineUpRowNumber  
            }
            LineUp.place = _LineUpRang;
            _LineUpPoints = LineUp.placePoints
        })
    }

    const sxProps:SxProps = {
        display: {
            xs: 'none',
            sm: 'table-cell'
        } 
    }

    return (
        <div>
            {LineUps !== undefined && LineUps.length > 0} {
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>PL.</TableCell>
                                <TableCell>NAME</TableCell>
                                <TableCell align='right'>#GT</TableCell>
                                <TableCell align='right' sx={sxProps}>GT</TableCell>
                                <TableCell align='right' >#V</TableCell>
                                <TableCell align='right' sx={sxProps}>V</TableCell>
                                <TableCell align='right' >#T</TableCell>
                                <TableCell align='right' sx={sxProps}>T</TableCell>
                                <TableCell align='right' sx={sxProps}>#N</TableCell>
                                <TableCell align='right' sx={sxProps}>N</TableCell>
                                <TableCell align='right' sx={sxProps}>TDT</TableCell>
                                <TableCell align='right' sx={sxProps}>SPDT</TableCell>
                                <TableCell align='right'>PKT.</TableCell>
                                <TableCell align='right' sx={sxProps}>GES</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {LineUps?.map((item:LineUp) => (
                                <TableRow 
                                    key={item.userSaisonAllocId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className={item.userSaisonAllocId === props.UserSaisonAlloc.id ? 'me-self' : ''}
                                >
                                    <TableCell>{item.place}</TableCell>
                                    <TableCell>{props.UserSaisonAllocs?.find(m => m.id === item.userSaisonAllocId)?.playerName}</TableCell>
                                    <TableCell align='right' >{item.goalsConceded}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.goalsConcededPlacePoints}</TableCell>
                                    <TableCell align='right'>{item.assists}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.assistsPlacePoints}</TableCell>
                                    <TableCell align='right' >{item.goals}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.goalsPlacePoints}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.teamPlayerPoints}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.teamPlayerPointPlacePoints}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.teamOfDayPoints}</TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.playerOfDayPoints}</TableCell>
                                    <TableCell align='right'>
                                        {
                                            item.goalsConcededPlacePoints +
                                            item.goalsPlacePoints +
                                            item.assistsPlacePoints +
                                            item.teamPlayerPointPlacePoints +
                                            item.teamOfDayPoints + 
                                            item.playerOfDayPoints
                                        }
                                    </TableCell>
                                    <TableCell align='right' sx={sxProps}>{item.placePoints}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    )
}