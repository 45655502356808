import React from "react"
import { Player } from "../../types/Common"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, TextField, styled } from "@mui/material"
import HttpService from "../../services/HttpService"
import moment from "moment"

const PlayerDialogComponent = (
    props: {
        open:boolean
        onClose: () => void
        onSave: () => void
        player?:Player
    }) => {

    const [id, setId] = React.useState("")
    const [name, setName] = React.useState("")
    const [birthday, setBirthday] = React.useState("")
    const [picture, setPicture] = React.useState("");
    const [pictureBlob, setPictureBlob] = React.useState();

    React.useEffect(() => {

        setName("");
        setBirthday("");
        setId("");
        setPicture("");
        setPictureBlob(undefined);

        if(props.player) {
            setName(props.player.name)
            setBirthday(props.player.birthday ? moment(props.player.birthday).format('YYYY-MM-DD') : "")
            if(props.player.id) {
                setId(props.player.id);
            }
            if(props.player.imageLogoPath) {
                setPicture(HttpService.baseUrl + '/images/upload/players/' + props.player.imageLogoPath)
            }
        }
    }, [props.player])

    const handleCancel = () => {
        props.onClose();
    }

    const handleOk = () => {

        var formData = new FormData();
        if(pictureBlob) {
            formData.append("picture", pictureBlob)
        }
        formData.append("id", id)
        formData.append("name", name)
        formData.append("birthday", birthday)

        HttpService.post('/CommonAPI/Player', 
            formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }}
          ).then(res => {
            if(res.status === 200) {
                props.onSave()
            }   
          });
    }

    const handleCapture = (event:any) => {
        setPicture(URL.createObjectURL(event.target.files[0]))
        setPictureBlob(event.target.files[0])
    }

    return (
        <PlayerDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
            <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + picture + ')'}} />
                {props.player && (
                    <Box marginLeft={2}>{props.player?.name}</Box>
                )}
                {!props.player && (
                    <Box marginLeft={2}>NEUER SPIELER</Box>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <TextField size="small" fullWidth label="NAME" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
                <TextField sx={{marginTop: 3}} fullWidth={true} label="GEBURTSTAG" variant="outlined" size="small" value={birthday} onChange={e => setBirthday(e.target.value)} type='date'/>
                <TextField sx={{marginTop: 3}} fullWidth={true} variant="outlined" size="small" type="file" onChange={handleCapture}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>ABBRECHEN</Button>
                <Button onClick={handleOk}>ÜBERNEHMEN</Button>
            </DialogActions>
        </PlayerDialog>
    )

}
export default PlayerDialogComponent

const PlayerDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))