import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { MatchDay, MatchDayEvent, MatchDayMatch, MatchDayState, MatchDayTeam, MatchEventType } from "../../types/MatchDay";
import { AllocRole, LineUpItem, LineUpItemChangeLog, Team, TeamPlayer, UserSaisonAlloc } from "../../types/Saison";
import HttpService from "../../services/HttpService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "../common/ConfirmationDialog";
import React from "react";
import RedCard from "../../res/images/RedCard.png";
import YellowRedCard from "../../res/images/YellowRedCard.png";
import SoccerBall  from "../../res/images/SoccerBall.png"

function MatchDayEventsComponent (
    props: {
        MatchDayEvents:MatchDayEvent[],
        Teams:Team[],
        TeamPlayers:TeamPlayer[],
        MatchDay:MatchDay,
        MatchDayMatches:MatchDayMatch[],
        MatchDayTeams:MatchDayTeam[],
        LineUpItems:LineUpItem[],
        UserSaisonAlloc:UserSaisonAlloc
    }
) {

    const navigate = useNavigate();

    const [ showConfirmDialog, setShowConfirmDialog ] = React.useState(false);
    const [ eventToDelete, setEventToDelete ] = React.useState("");
    const [ reducerValue, forceUpdate ] = React.useReducer(x => x + 1, 0);

    const handleMatchClick = (matchDayMatchId:string) => {
        navigate('/match/' + matchDayMatchId)
    }

    const handleDeleteEvent = (MatchEventId:string) => {
        setEventToDelete(MatchEventId);
        setShowConfirmDialog(true);
    }

    React.useEffect(function() {
    },[reducerValue])

    const deleteEvent = () => {
        if(eventToDelete) {
            HttpService.delete('/MatchDayAPI/MatchEvent', {
                params: {
                    MatchEventId: eventToDelete
                }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                } 

                var deletedEvent = props.MatchDayEvents.find(s => s.id === eventToDelete)

                if(deletedEvent) {
                    const index = props.MatchDayEvents.indexOf(deletedEvent, 0);
                    console.log(index);
                    if (index > -1) {
                        console.log('splice');
                        props.MatchDayEvents.splice(index, 1);
                    }
                }
                setShowConfirmDialog(false);
                forceUpdate();
            }); 
        }
    }

    return (

        <div>
        {props.MatchDayEvents.length === 0 && (
            <Card variant="outlined" sx={{marginBottom: 2, backgroundColor: 'lightgray'}}>
                <CardContent>
                    <Typography textAlign='center'>Keine Tickermeldungen vorhanden!</Typography>
                </CardContent>
            </Card>
        )}

        {props.MatchDayEvents.map((MatchDayEvent) => (
            <Card variant="outlined" key={MatchDayEvent.id} sx={{marginBottom: 2}}> 
                <CardContent>
                    <Grid container>
                        <Grid item xs={1} marginBottom={'10px'}>
                            {MatchDayEvent.matchEventType === MatchEventType.GOAL && (
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + SoccerBall + ')'}}></Box>
                            )}
                            {MatchDayEvent.matchEventType === MatchEventType.RED_CARD && (
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + RedCard + ')'}}></Box>
                            )}
                            {MatchDayEvent.matchEventType === MatchEventType.YELLOW_RED_CARD && (
                                <Box className='teamlogo-fit' textAlign='left' height={'25px'} width={'25px'} sx={{backgroundImage:'url(' + YellowRedCard + ')'}}></Box>
                            )}
                        </Grid>
                        <Grid item xs={true} sx={{justifyContent: 'center', display: 'grid'}}>
                            <Grid container sx={{cursor: 'pointer'}} onClick={() => handleMatchClick(MatchDayEvent.matchDayMatchId)}>
                                <Grid>
                                    {/* <Typography sx={{ display: { xs: 'none', sm: 'block' } }} whiteSpace='nowrap' overflow='hidden' fontWeight='bold'>{props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId1)?.teamId)?.name}</Typography> */}
                                    <Typography fontWeight='bold' fontSize={'larger'}>{props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId1)?.teamId)?.shortName}</Typography>
                                </Grid>
                                <Grid item minWidth={'30px'} sx={{background: 'center'}}>
                                    <Box className='teamlogo-fit' height={'25px'} sx={{backgroundPositionY: 'inherit', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId1)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box textAlign='center' paddingTop='4px' paddingLeft='10px' paddingRight='10px' marginLeft='5px' marginRight='5px' sx={{
                                        backgroundColor: '#000000', 
                                        color: '#CCCCCC',
                                        maxWidth: '60px',
                                        borderRadius: '5px',
                                        height: '30px',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                        
                                        }}>
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <Typography  fontWeight='bold' lineHeight='1.4' textAlign="right" paddingRight={1}>
                                                    {MatchDayEvent.matchDayTeam1Goals}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography fontWeight='bold' lineHeight='1.4'>
                                                :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography  fontWeight='bold' lineHeight='1.4' textAlign="left" paddingLeft={1}>
                                                    {MatchDayEvent.matchDayTeam2Goals}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item minWidth={'30px'} sx={{background: 'center'}}>
                                    <Box className='teamlogo-fit' height={'25px'} sx={{backgroundPositionY: 'inherit', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId2)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                                    </Box>
                                </Grid>
                                <Grid item>
                                    {/* <Typography sx={{ display: { xs: 'none', sm: 'block' } }} whiteSpace='nowrap' overflow='hidden' fontWeight='bold'>{props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId2)?.teamId)?.name}</Typography> */}
                                    <Typography fontWeight='bold' fontSize={'larger'}>{props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === props.MatchDayMatches.find(s => s.id === MatchDayEvent.matchDayMatchId)?.matchDayTeamsId2)?.teamId)?.shortName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} textAlign={'right'}>
                            {props.UserSaisonAlloc.allocRole === AllocRole.owner && props.MatchDay.state === MatchDayState.OPENED && (
                                <Box textAlign='right'><Delete sx={{cursor: 'pointer' }} onClick={() => handleDeleteEvent(MatchDayEvent.id)} /></Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container marginTop={'2px'} sx={{cursor: 'pointer'}} onClick={() => handleMatchClick(MatchDayEvent.matchDayMatchId)}>
                        {MatchDayEvent.matchEventType === MatchEventType.GOAL && (
                            <Box>
                                <Typography fontSize={'medium'} fontWeight='bold'>TOR für {props.Teams.find(s => s.id === props.MatchDayTeams.find(s => s.id === MatchDayEvent.matchDayTeamId)?.teamId)?.name}!</Typography>
                                {MatchDayEvent.lineUpItemIdGoal && (
                                    <Typography fontSize={'medium'}>Torschütze: {props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdGoal)?.teamPlayerId)?.name} ({props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdGoal)?.teamPlayerId)?.number})</Typography>
                                )}
                                {MatchDayEvent.lineUpItemIdAssist && (
                                    <Typography fontSize={'medium'}>Vorarbeit: {props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdAssist)?.teamPlayerId)?.name} ({props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdAssist)?.teamPlayerId)?.number})</Typography>
                                )}
                            </Box>
                        )}
                        {MatchDayEvent.matchEventType === MatchEventType.RED_CARD && (
                            <Box>
                                <Typography fontSize={'medium'} fontWeight='bold'>ROTE KARTE für {props.Teams.find(s => s.id === props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.teamId)?.name}!</Typography>
                                <Typography fontSize={'medium'} fontWeight='medium'>{props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.name} ({props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.number})</Typography>
                            </Box>
                        )}
                        {MatchDayEvent.matchEventType === MatchEventType.YELLOW_RED_CARD && (
                            <Box>
                                <Typography fontSize={'medium'} fontWeight='bold'>GELB/ROTE KARTE für {props.Teams.find(s => s.id === props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.teamId)?.name}!</Typography>
                                <Typography fontSize={'medium'} fontWeight='medium'>{props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.name} ({props.TeamPlayers.find(s => s.id === props.LineUpItems.find(s => s.id === MatchDayEvent.lineUpItemIdCard)?.teamPlayerId)?.number})</Typography>
                            </Box>
                        )}
                    </Grid>
                    <CardActionArea sx={{cursor: 'pointer'}} onClick={() => handleMatchClick(MatchDayEvent.matchDayMatchId)}>
                        <Typography fontSize={'x-small'}>{moment(MatchDayEvent.date).format('DD.MM.YYYY HH:mm')}</Typography> 
                    </CardActionArea>
                </CardContent>      
            </Card>
        ))}
            <ConfirmationDialog
                open={showConfirmDialog} 
                onCancel={() => setShowConfirmDialog(false)}
                onNo={() => setShowConfirmDialog(false)}
                title="LÖSCHEN?"
                onYes={deleteEvent}
            >
                Wirklich löschen?
            </ConfirmationDialog>
        </div>
    )

}

export default MatchDayEventsComponent