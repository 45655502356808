import React from "react";
import HttpService from "../../services/HttpService";
import { SaisonEvent } from "../../types/Saison";
import { Box, Fade, Typography } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import moment from "moment";
import EventDetailsDialog from "./EventDetailsDialog";
import { EventOutlined, FastfoodOutlined, SportsOutlined } from "@mui/icons-material";

export default function EventsComponent(props: {SaisonId:string}) {

    const [ events, setEvents ] = React.useState<SaisonEvent[]>(new Array<SaisonEvent>())
    const [ selectedEvent, setSelectedEvent ] = React.useState<SaisonEvent>();
    const [ showDetailsDialog, setShowDetailsDialog ] = React.useState(false)

    React.useEffect(function() {

        HttpService.get('/SaisonAPI/Events', {
            params: { saisonId: props.SaisonId }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }                    
                setEvents(res.data.events);
            });    
    }, [props.SaisonId])

    return (
        <Box>
            {events && events.length > 0 && (
                <Fade in>
                    <Box>
                        <Timeline position="alternate" sx={{mt: '0px', padding: 0}}>
                            {events.map((Event) => (
                                <TimelineItem key={Event.id} sx={
                                        moment().format('YYYYMMDD') > moment(Event.startDate).format('YYYYMMDD') && moment().format('YYYYMMDD') > moment(Event.endDate).format('YYYYMMDD') ? {opacity: 0.4} : {}
                                        }>
                                    <TimelineOppositeContent overflow={'hidden'}
                                        sx={{ m: 'auto 0' }}
                                        fontWeight={moment().format('YYYYMMDD') >= moment(Event.startDate).format('YYYYMMDD') && moment().format('YYYYMMDD') <= moment(Event.endDate).format('YYYYMMDD') ? 'bold' : ''}
                                        align="right"
                                        variant="body2"
                                        color={moment().format('YYYYMMDD') === moment(Event.startDate).format('YYYYMMDD') && moment().format('YYYYMMDD') === moment(Event.endDate).format('YYYYMMDD') ? '#880000' : 'text.secondary'}
                                    >
                                        {moment(Event.startDate).format('DD.MM.YYYY') === moment(Event.endDate).format('DD.MM.YYYY') && (
                                            moment(Event.startDate).format('DD.MM.YYYY')
                                        )}
                                        {moment(Event.startDate).format('DD.MM.YYYY') !== moment(Event.endDate).format('DD.MM.YYYY') && (
                                            moment(Event.startDate).format('DD.MM.YYYY') + " - " + moment(Event.endDate).format('DD.MM.YYYY')
                                        )}
                                        {/* {moment(Event.startDate).format('DD.MM.YYYY')} */}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot>
                                        {Event.title === 'Saisonbeginn' ? <SportsOutlined fontSize="small" /> : <EventOutlined fontSize="small" />}
                                    </TimelineDot>
                                    <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2, textOverflow: 'ellipsis', whiteSpace: 'normal' }} alignSelf={'center'} overflow={'hidden'}>
                                    <Typography                                      
                                        fontWeight={moment().format('YYYYMMDD') >= moment(Event.startDate).format('YYYYMMDD') && moment().format('YYYYMMDD') <= moment(Event.endDate).format('YYYYMMDD') ? 'bold' : ''}
                                        color={moment().format('YYYYMMDD') === moment(Event.startDate).format('YYYYMMDD') && moment().format('YYYYMMDD') === moment(Event.endDate).format('YYYYMMDD') ? '#880000' : ''}
                                        component="span" 
                                        sx={{cursor: 'pointer', overflow: 'hidden'}} 
                                        onClick={() => {setSelectedEvent(Event); setShowDetailsDialog(true)}}
                                    >
                                        {Event.title}
                                    </Typography>
                                        <Typography>{Event.location}</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Box>
                </Fade>
            )}
            {events.length === 0 && (
                <Box textAlign={'center'}>KEINE TERMINE</Box>
            )}
            {selectedEvent && (
                <EventDetailsDialog 
                    event={selectedEvent}
                    open={showDetailsDialog}
                    onClose={() => setShowDetailsDialog(false)}
                />
            )}

        </Box>
    )
}