import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from "@mui/material";
import React from "react";
import HttpService from "../../services/HttpService";
import { Fade } from "react-awesome-reveal";
import DialogProgressComponent from "../common/DialogProgressComponent";

export interface Props {
    open: boolean;
    saisonId: string,
    onOk: () => void;
    onAbort: () => void;
}

function AddManagerDialogComponent(props: Props) {
    const { open, onOk, onAbort } = props;
    const [ email, setEmail ] = React.useState("");
    const [ loading, setLoading ] = React.useState(false);

    const handleOk = () => {
        setLoading(true);
        HttpService.get("/SaisonAPI/AddManager", {
            params: { email: email, saisonId: props.saisonId }
            }).then(res => {if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(res.data);
            }
            setLoading(false);
            onOk();
            setEmail("");
          });    
    }

    const handleAbort = () => {
        onAbort();
        setEmail("");
    }

    return(

        <Dialog fullWidth={true} open={open}>
            <DialogTitle>MANAGER HINZUFÜGEN</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
                <TextField fullWidth={true}
                    label="E-Mail"
                    variant="standard"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleAbort} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading || email.length === 0}>ÜBERNEHMEN</Button>
            </DialogActions>
        </Dialog>

    )

}

export default AddManagerDialogComponent