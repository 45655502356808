import { ModelTraining } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from 'react'
import HttpService from '../../services/HttpService';
import { LineUpItem, TeamPlayer } from '../../types/Saison';

export interface Props {
    open: boolean;
    selectedTeamPlayer?: TeamPlayer
    onClose: () => void;
}

function LineUpItemCommentDialogComponent(props: Props){
    const { open, onClose, selectedTeamPlayer } = props;
    const [ note, setNote ] = React.useState("");

    React.useEffect(() => {
        if(selectedTeamPlayer?.note) {
            setNote(selectedTeamPlayer.note)
        } else {
            setNote("")
        }
    },[selectedTeamPlayer])

    const handleOk = () => {

        if(selectedTeamPlayer)
            selectedTeamPlayer.note = note;

        HttpService.post("/SaisonApi/TeamPlayerNote", 
            selectedTeamPlayer
        ).then(res => {
            if(res.status === 200) {
                selectedTeamPlayer!.noteDate = res.data.noteDate;
                onClose()
            }   
        })
    }

    const handleCancel = () => {
        onClose();
    }

    return (
        <Dialog fullWidth={true} open={open}>
            <DialogTitle>BEMERKUNG | {selectedTeamPlayer?.name}</DialogTitle>
            <DialogContent dividers>
                <TextField fullWidth={true}
                    label="Bemerkung"
                    multiline
                    rows={4}
                    variant="standard"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>ABBRECHEN</Button>
                <Button onClick={handleOk}>ÜBERNEHMEN</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LineUpItemCommentDialogComponent