import React from "react"
import { TeamSuggest } from "../../types/Common"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, TextField, styled } from "@mui/material"
import HttpService from "../../services/HttpService"
import AlertDialog, { AlertType } from "./AlertDialog"

const TeamSuggestDialogComponent = (props: {

    open:boolean
    onClose: () => void
    onSave: () => void
    teamSuggest?:TeamSuggest
    }) => {
    
    const [id, setId] = React.useState("")
    const [name, setName] = React.useState("")
    const [shortName, setShortName] = React.useState("")
    const [logoPath, setLogoPath] = React.useState("");
    const [logo, setLogo] = React.useState();

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");

    React.useEffect(() => {

        if(props.teamSuggest) {

            setId(props.teamSuggest.id)
            setName(props.teamSuggest.name)
            setShortName(props.teamSuggest.shortName)
            setLogoPath(HttpService.baseUrl + '/images/upload/teams/' + props.teamSuggest.imageLogoPath)
        }
        
    }, [props.teamSuggest])

    const reset = () => {

        setId("")
        setName("")
        setShortName("")
        setLogoPath("")
        setLogo(undefined)

    }

    const handleCancel = () => {
        props.onClose();
        reset()
    }

    const handleSave = () => {

        var formData = new FormData();
        if(logo) {
            formData.append("logo", logo)
        }
        formData.append("id", id)
        formData.append("name", name)
        formData.append("shortName", shortName)

        HttpService.post('/CommonAPI/TeamSuggest', 
            formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }}
          ).then(res => {
            if(res.status === 200) {
                props.onSave()
                reset()
            }   
          });
    }
    
    const handleCapture = (event:any) => {
        if(event.target.files[0].size > 240000) {
            setAlertMessage("Datei zu groß")
            setShowAlert(true)
        } else {
            setLogoPath(URL.createObjectURL(event.target.files[0]))
            setLogo(event.target.files[0])
        }
    }

    return (
        <TeamSuggestDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + logoPath + ')'}} />
                {props.teamSuggest && (
                    <Box marginLeft={2}>{props.teamSuggest?.name}</Box>
                )}
                {!props.teamSuggest && (
                    <Box marginLeft={2}>NEUER VEREIN</Box>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <TextField size="small" fullWidth label="NAME" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
                <TextField sx={{marginTop: 3}} size="small" fullWidth label="NAME (KURZ)" variant="outlined" value={shortName} onChange={e => setShortName(e.target.value)} />
                <TextField sx={{marginTop: 3}} fullWidth={true} variant="outlined" size="small" type="file" onChange={handleCapture}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>ABBRECHEN</Button>
                <Button onClick={handleSave}>ÜBERNEHMEN</Button>
            </DialogActions>
            <AlertDialog open={showAlert} onOk={() => setShowAlert(false)} title="Fehler" type={AlertType.ERROR} message={alertMessage} />
        </TeamSuggestDialog>
    )
}

export default TeamSuggestDialogComponent

const TeamSuggestDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))