import { AccountCircle, ArrowDropDown, ArrowDropUp, ArrowRight, BarChart, EmojiEvents, Person } from "@mui/icons-material";
import { Box, Button, Grid, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { red } from "@mui/material/colors";
import { Manager, SaisonState, UserSaisonAlloc } from "../../types/Saison";
import { Fade } from "react-awesome-reveal";
import React from "react";
import ManagerStatisticsDialogComponent from "./ManagerStatisticsDialogComponent";
import HttpService from "../../services/HttpService";
import ImageComponent from "../common/ImageComponent";

export default function ManagerRanking(props: 
    { 
        Managers?: Manager[], 
        UserSaisonAlloc: UserSaisonAlloc, 
        SaisonState:SaisonState;
        SaisonRanking?:boolean;
    }) {

    const {Managers} = props;
    const [openManagerStatisticsDialog, setOpenManagerStatisticsDialog] = React.useState(false)
    const [selectedManager, setSelectedManager ] = React.useState("");

    if(Managers) {
        Managers.sort(function(a: Manager,b: Manager){return a.points<b.points?1:a.points>b.points?-1:a.goals<b.goals?1:a.goals>b.goals?-1:0});

        var _Number = 0;
        var _Rang = 0;
        var _Goals = 0;
        var _Points = 0;

        Managers.forEach(function(item: Manager) {
            _Number++;

            if (!(_Points === item.points && _Goals === item.goals))
                _Rang = _Number;

            _Points = item.points;
            _Goals = item.goals;
            item.rang = _Rang;
        });

        Managers.sort(function(a: Manager,b: Manager){return a.prePoints<b.prePoints?1:a.prePoints>b.prePoints?-1:a.preGoals<b.preGoals?1:a.preGoals>b.preGoals?-1:0});

        var _PreNumber = 0;
        var _PreRang = 0;
        var _PreGoals = 0;
        var _PrePoints = 0;

        Managers.forEach(function(item: Manager) {
            _PreNumber++;

            if (!(_PrePoints === item.prePoints && _PreGoals === item.preGoals))
                _PreRang = _PreNumber;

            _PrePoints = item.prePoints;
            _PreGoals = item.preGoals;
            item.preRang = _PreRang;
        });

        Managers.sort((a: Manager,b: Manager) => (a.rang > b.rang) ? 1 : ((b.rang > a.rang) ? -1 : 0))
    }
    
    const sxProps:SxProps = {
        display: {
            xs: 'none',
            sm: 'table-cell'
        } 
    }

    const handleOpenManagerStatistics = (userSaisonAllocId:string) => {
        setSelectedManager(userSaisonAllocId)
        setOpenManagerStatisticsDialog(true)
    }

    const AvatarTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
            ))({
            [`& .${tooltipClasses.tooltip}`]: {
                // width: "232px",
                // height: "215px",
                backgroundColor: "transparent"
            },
    });

    return(
        <div>
            {Managers !== undefined && Managers.length > 0 && (
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>PL.</TableCell>
                                <TableCell colSpan={2}>NAME</TableCell>
                                <TableCell align="right">SP.</TableCell>
                                <TableCell align="right">TORE</TableCell>
                                <TableCell align="right">PKT.</TableCell>
                                {/* <TableCell></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {Managers && (
                            Managers.map((item: Manager) => (
                                <TableRow
                                    key={item.userSaisonAlloc.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                    className={item.userSaisonAlloc.id === props.UserSaisonAlloc.id ? 'me-self' : ''}
                                    onClick={() => {
                                        handleOpenManagerStatistics(item.userSaisonAlloc.id) 
                                    }}
                                >
                                    <TableCell sx={{ width: 15 }}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                {item.rang}
                                            </Grid>
                                            {props.SaisonState === SaisonState.Finished && item.rang === 1 && props.SaisonRanking && (
                                            <Grid item xs={6}>
                                                <EmojiEvents sx={{color: "#DAA520", fontSize: "1.3rem", marginBottom: '-5px'}} />
                                            </Grid>
                                            )}
                                        </Grid>
                                    </TableCell>
                                    <TableCell sx={{ width: 15, paddingTop: 0, paddingBottom: 0, padding: 0 }}>                                               
                                            {item.rang < item.preRang && item.matchDays > 1 && (
                                                <ArrowDropUp color="success" style={{verticalAlign: 'bottom'}}/>
                                            )}
                                            {item.rang > item.preRang && item.matchDays > 1 && (
                                                <ArrowDropDown sx={{ color: red[500], marginBottom: '-5px' }} />
                                            )}
                                            {(item.rang === item.preRang || item.matchDays < 2) && (
                                                <ArrowRight sx={{marginBottom: '-5px'}}/>
                                            )}
                                    </TableCell>
                                    <TableCell sx={{ width: 15, paddingTop: 0, paddingBottom: 0 }}>
                                        {item.userSaisonAlloc.avatar && (
                                            <Box>
                                                <AvatarTooltip key={item.userSaisonAlloc.id} placement="right" title={
                                                    <Box>
                                                        <ImageComponent 
                                                            className= {`avatar ${item.userSaisonAlloc.isOnline || item.userSaisonAlloc.id === props.UserSaisonAlloc.id ? 'avatar-online' : ''}`}
                                                            width={'150px'}
                                                            fileName={'/images/upload/avatar/' + item.userSaisonAlloc.avatar} 
                                                            base64Thumb={item.userSaisonAlloc.avatarThumbBase64}/>
                                                    </Box>
                                                }  
                                                >
                                                    <Box>
                                                        <ImageComponent
                                                            className= {`avatar ${item.userSaisonAlloc.isOnline || item.userSaisonAlloc.id === props.UserSaisonAlloc.id ? 'avatar-online' : ''}`}
                                                            fileName={'/images/upload/avatar/' + item.userSaisonAlloc.avatar} 
                                                            width={'25px'}
                                                            base64Thumb={item.userSaisonAlloc.avatarThumbBase64}/>
                                                    </Box>
                                                </AvatarTooltip>
                                            </Box>
                                        )}
                                        {!item.userSaisonAlloc.avatar && (
                                            <AccountCircle className={`icon-person ${item.userSaisonAlloc.isOnline || item.userSaisonAlloc.id === props.UserSaisonAlloc.id ? 'icon-blink' : ''}`} sx={{fontSize: "24px", marginBottom: '-5px'}}/>
                                        )}
                                    </TableCell>
                                    <TableCell sx={item.userSaisonAlloc.isOnline || item.userSaisonAlloc.id === props.UserSaisonAlloc.id ? {fontWeight: 'bold', color: '#005000'} : {}}>
                                        {item.userSaisonAlloc.playerName}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {item.matchDays}
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: 15 }}>
                                        {item.goals}
                                    </TableCell>
                                    <TableCell align="right" sx={{ width: 15 }}>
                                        {item.points}
                                    </TableCell>
                                    {/* <TableCell align="right"  sx={{ width: 15 }}>
                                        <Button sx={{padding: '0px'}} onClick={() => {
                                            handleOpenManagerStatistics(item.userSaisonAlloc.id) 
                                            }}>
                                            <BarChart />
                                        </Button>
                                    </TableCell> */}
                                </TableRow>
                            ))
                        )}
                        </TableBody>
                    </Table>
                    <ManagerStatisticsDialogComponent 
                        onClose={() => setOpenManagerStatisticsDialog(false)}
                        open={openManagerStatisticsDialog}
                        saisonId={props.UserSaisonAlloc.saisonId}
                        userSaisonAllocId={selectedManager}
                    />
                </TableContainer>
            )}
        </div>
        
    )
}