import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, styled } from "@mui/material";
import { MatchDayMatch, MatchDayTeam } from "../../types/MatchDay";
import { Team, UserSaisonAlloc } from "../../types/Saison";
import React from "react";
import { EditTipp, Tipp } from "../../types/Tipp";
import HttpService from "../../services/HttpService";
import moment from "moment";
import { Fade } from "react-awesome-reveal";
import DialogProgressComponent from "../common/DialogProgressComponent";

function EditTippsDialogComponent(props: {
    open: boolean,
    onClose: () => void,
    matchDayMatches:MatchDayMatch[],
    matchDayTeams:MatchDayTeam[],
    tipps:Tipp[],
    teams:Team[],
    userSaisonAlloc:UserSaisonAlloc,
    serverDateTime:string
}) {
    const { open, onClose } = props
    const [ saveable, setIsSaveable ] = React.useState(false)
    const [ reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [ newTipps, setNewTipps ] = React.useState(new Array<EditTipp>())
    const [ loading, setLoading ] = React.useState(false);

    const handleCancel = () => {
        setNewTipps(new Array<EditTipp>());
        setIsSaveable(false);
        onClose();
    }

    const handleOk = () => {

        setIsSaveable(false)
        setLoading(true)

        HttpService.post('/MatchDayAPI/MatchDayTipps', newTipps
        ).then(res => {

            if(res.status === 200) {

                newTipps.forEach((NewTipp) => {
                    const Tipp = props.tipps.find(s=>s.matchDayMatchId === NewTipp.matchDayMatchId && s.userSaisonAllocId === props.userSaisonAlloc.id);
                    if(Tipp !== undefined) {
                        Tipp.team1GoalsTipp = NewTipp.team1Goal;
                        Tipp.team2GoalsTipp = NewTipp.team2Goal;
                    } else {
                        props.tipps.push({
                            matchDayMatchId: NewTipp.matchDayMatchId,
                            team1GoalsTipp: NewTipp.team1Goal,
                            team2GoalsTipp: NewTipp.team2Goal,
                            points: 0,
                            userSaisonAllocId: NewTipp.userSaisonAllocId
                        })
                    }
                })

                setNewTipps(new Array<EditTipp>());
                setLoading(false)
                onClose();
            } 
        })
    }

    const handleChangeTipp = (MatchDayMatch:MatchDayMatch, value:number, team:number) => {

        var tipp:EditTipp|undefined;

        tipp = newTipps.find(s => s.matchDayMatchId === MatchDayMatch.id && s.userSaisonAllocId === props.userSaisonAlloc.id);

        if(tipp === undefined) {
            tipp = {
                matchDayMatchId: MatchDayMatch.id,
                userSaisonAllocId: props.userSaisonAlloc.id,
                team1Goal: 0,
                team2Goal: 0
            }

            var existingTipp = props.tipps.find(s => s.matchDayMatchId === MatchDayMatch.id && s.userSaisonAllocId === props.userSaisonAlloc.id)

            if(existingTipp !== undefined) {
                tipp.team1Goal = existingTipp.team1GoalsTipp
                tipp.team2Goal = existingTipp.team2GoalsTipp
            }

            newTipps.push(tipp);
        }

        if(value < 0) {
            value = 0;
        }

        if(team === 1) {
            tipp.team1Goal = value;
        } else {
            tipp.team2Goal = value;
        }

        setIsSaveable(true);
        forceUpdate();
    }

    return (
        <EditTippsDialog open={open}>
            <DialogTitle>TIPPS</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
            {props.matchDayMatches.map((MatchDayMatch) => (
                <Grid container key={MatchDayMatch.id} marginBottom='5px'>
                    <Grid item xs={true} textAlign={'right'} paddingRight='10px'>
                        {props.teams.find(s=>s.id === props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId1)?.teamId)?.shortName}
                    </Grid>
                    <Grid item xs='auto' paddingRight='5px'>
                        <Box className={moment(MatchDayMatch.matchDate).isBefore(moment(props.serverDateTime.split(".")[0])) ? "teamlogo-fit teamlogo-disabled" : 'teamlogo-fit'} width={'30px'} height={'30px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams.find(s=>s.id === props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId1)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                        </Box>
                    </Grid>
                    <Grid item xs={2} paddingRight='5px'>
                        <TextField fullWidth={true} size="small"
                            defaultValue={
                                 newTipps.find(s => s.matchDayMatchId === MatchDayMatch.id) ? newTipps.find(s => s.matchDayMatchId === MatchDayMatch.id)?.team1Goal :
                                 props.tipps.find(s => s.matchDayMatchId === MatchDayMatch.id && s.userSaisonAllocId === props.userSaisonAlloc.id)?.team1GoalsTipp}
                            onChange={e => handleChangeTipp(MatchDayMatch, Number(e.target.value), 1)}
                            type='number'
                            disabled={moment(MatchDayMatch.matchDate).isBefore(moment(props.serverDateTime.split(".")[0]))}  
                        />
                    </Grid>
                    <Grid item xs={2} paddingLeft='5px'>
                        <TextField fullWidth={true} size="small"
                            defaultValue={
                                newTipps.find(s => s.matchDayMatchId === MatchDayMatch.id) ? newTipps.find(s => s.matchDayMatchId === MatchDayMatch.id)?.team2Goal :
                                props.tipps.find(s => s.matchDayMatchId === MatchDayMatch.id  && s.userSaisonAllocId === props.userSaisonAlloc.id)?.team2GoalsTipp}
                            onChange={e => handleChangeTipp(MatchDayMatch, Number(e.target.value), 2)}
                            type='number'  
                            disabled={moment(MatchDayMatch.matchDate).isBefore(moment(props.serverDateTime.split(".")[0]))}                     
                        />
                    </Grid>
                    <Grid item xs='auto' paddingLeft='5px'>
                        <Box className={moment(MatchDayMatch.matchDate).isBefore(moment(props.serverDateTime.split(".")[0])) ? "teamlogo-fit teamlogo-disabled" : 'teamlogo-fit'} width={'30px'} height={'30px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.teams.find(s=>s.id === props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId2)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                        </Box>
                    </Grid>
                    <Grid item xs={true} textAlign={'left'} paddingLeft='10px'>
                        {props.teams.find(s=>s.id === props.matchDayTeams.find(s => s.id === MatchDayMatch.matchDayTeamsId2)?.teamId)?.shortName}
                    </Grid>
                </Grid>
            ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={!saveable || loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </EditTippsDialog>
    )
}

const EditTippsDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))

export default EditTippsDialogComponent;