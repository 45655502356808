import { Badge } from "@mui/material"
import { TeamPlayerPosition } from "../../types/Saison"

const ShirtComponent = (props: {
    number?:number
    teamPlayerPosition?:TeamPlayerPosition
    height?:string
    width?:string
    notPlayed?:boolean
    substitude?:boolean
    playerOfDay?:boolean
    teamOfDay?:boolean
    showPosition?:boolean
}) => {

    var shirtColor = "white";
    var shirtColorBorder = "black"
    var position = "";

    switch(props.teamPlayerPosition) {
        case TeamPlayerPosition.goalkeeper: 
            shirtColor = 'indianRed';
            position = 'T'
            break;
        case TeamPlayerPosition.defender: 
            shirtColor = 'steelblue';
            position = 'A'
            break;
        case TeamPlayerPosition.midfielder: 
            shirtColor = 'seagreen';
            position = 'M'
            break;
        case TeamPlayerPosition.striker: 
            shirtColor = 'dimgray';
            position = 'S'
            break;
    }

    if(props.teamOfDay) {
        shirtColor = "url(#bronze)";
    }

    if(props.playerOfDay) {
        shirtColor = "url(#gold)";
    }

    return (
        <Badge className={props.showPosition ? "positionBadge" : ""} badgeContent={props.showPosition ? position : ""}>
        <div style={{height: props.height ? props.height : '37px'}}>
            <svg height={props.height ? props.height : '37px'} width={props.width ? props.width : '37px'} viewBox="0 0 60.674 60.674" opacity={props.substitude ? 1 : 1}>
                <path xmlns="http://www.w3.org/2000/svg" d="M46.682,6.95c-0.675-0.675-1.813-1.147-2.768-1.147h-5.818c-0.134,0-1.318,0.04-1.516,1.438    c-0.02,0.14-0.05,0.351-0.121,0.56c-0.473,1.384-2.979,2.815-6.353,2.815s-5.88-1.432-6.353-2.815    c-0.408-1.194-1.446-1.997-2.584-1.997h-5.397c-0.627,0-0.627,0-6.953,6.322l-8.086,8.082C0.261,20.68,0.001,21.308,0,21.975    c0,0.669,0.259,1.297,0.732,1.77l7.714,7.716c0.944,0.945,2.59,0.945,3.536,0l2.289-2.29v23.2c0,1.379,1.122,2.5,2.5,2.5h26.854    c1.378,0,2.5-1.121,2.5-2.5V28.892l2.568,2.568c0.944,0.945,2.591,0.945,3.535,0l7.714-7.716c0.472-0.472,0.732-1.1,0.732-1.768    s-0.26-1.296-0.732-1.767L46.682,6.95z M10.214,28.985l-7.007-7.008c0.571-0.571,1.199-1.199,1.857-1.857l7.103,6.912    L10.214,28.985z M44.924,24.252c-0.894,0-1.799,0.692-1.799,2.018v25.601H17.271V26.548c0-1.325-0.905-2.017-1.799-2.017    c-0.367,0-0.919,0.115-1.469,0.664l-1.128,1.129l-7.103-6.911C9.87,15.316,14.91,10.28,16.39,8.805h4.536    c0.993,2.835,4.76,4.813,9.18,4.813s8.188-1.978,9.18-4.813h4.628c0.162,0.002,0.531,0.155,0.647,0.268l10.473,10.472    l-7.103,6.911l-1.538-1.54C45.844,24.367,45.291,24.252,44.924,24.252z M50.46,28.985l-1.823-1.823l7.104-6.912l1.727,1.727 L50.46,28.985z"style={{fill: shirtColorBorder}} />
                <path d="M 5.574 19.473 L 16.274 8.772 L 21.126 8.856 C 20.932 10.885 26.658 13.784 29.899 13.54 C 33.511 13.697 37.116 12.506 39.32 8.787 L 44.392 8.704 L 55.222 19.541 C 55.222 19.541 47.429 26.608 47.927 26.654 C 48.425 26.7 43.73 22.757 43.858 25.238 C 42.776 21.275 43.401 52.873 43.327 52.03 L 17.219 51.878 C 17.219 51.878 17.423 26.131 16.18 25.483 C 16.796 23.639 13.054 25.766 12.816 26.487 L 5.574 19.473 Z" style={{fill: shirtColor}} transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 7.105427357601002e-15)"/>

                {/* {props.teamPlayerPosition == TeamPlayerPosition.midfielder && (

                    <g transform="matrix(1, 0, 0, 1, 0, 8.881784197001252e-16)">
                        <path d="M 11.831 12.777 L 15.627 9.508 L 15.838 25.114 L 12.148 26.908 L 11.831 12.777 Z"/>
                        <path d="M 19.634 7.979 L 24.591 10.404 L 24.485 52.476 L 19.74 52.161 L 19.634 7.979 Z"/>
                        <path d="M 28.229 12.092 L 33.186 12.091 L 33.502 52.688 L 28.546 52.583 L 28.229 12.092 Z"/>
                        <path d="M 36.822 10.616 L 40.935 6.609 L 41.568 53.005 L 36.928 53.638 L 36.822 10.616 Z"/>
                        <path d="M 43.993 7.557 L 48 10.299 L 48.527 26.854 L 44.52 26.644 L 43.993 7.557 Z"/>
                    </g>
                )}

                {props.teamPlayerPosition == TeamPlayerPosition.striker && (
                    <path d="M 9.959 12.791 L 13.611 9.615 L 16.944 7.71 L 44.409 44.383 L 44.568 48.51 L 43.933 52.162 L 39.487 53.273 L 9.959 12.791 Z" transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 0)"/>
                )}
                
                {props.teamPlayerPosition == TeamPlayerPosition.defender && (
                    <path d="M 25.041 11.203 L 30.121 12.314 L 34.883 11.044 L 35.36 52.796 L 25.675 52.797 L 25.041 11.203 Z" transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 0)"/>

                )} */}

                {props.number && (
                    <text style={{fontSize: "21px", fontWeight: 700, whiteSpace: "pre", fill: props.teamOfDay || props.playerOfDay ? "#000000" : "#FFFFFF"}} x={props.number > 9 ? "18px" : "24px"} y="35" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 5.684341886080802e-14, 2.842170943040401e-14)">{props.number}</text>
                )}   
                {props.notPlayed && ( 
                    <g style={{fill: "darkred"}}>
                        <path d="M 4.646 52.495 L 52.806 3.29 C 54.746 1.149 58.446 5.428 57.152 6.42 L 8.992 56.319 C 7.369 57.942 3.023 54.118 4.646 52.495 Z"  transform="matrix(1, 0, 0, 0.9999999999999999, 0, 0)"/>
                        <path d="M 1.516 7.463 C -0.269 4.47 3.779 1.643 5.862 3.464 L 58.197 50.408 C 60.444 52.575 56.094 56.919 54.198 54.928 L 1.516 7.463 Z"  transform="matrix(1, 0, 0, 0.9999999999999999, 0, 0)"/>
                    </g>
                )}


            <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="gold" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                <stop offset="0" style={{stopColor: "rgb(249, 232, 192)"}} />
                <stop offset="1" style={{stopColor: " rgb(232, 167, 58)"}} />
            </radialGradient>

            <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="silver" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                <stop offset="0" style={{stopColor: "rgb(233, 232, 231)"}} />
                <stop offset="1" style={{stopColor: " rgb(174, 173, 172)"}} />
            </radialGradient>
  
            <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="bronze" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                <stop offset="0" style={{stopColor: "rgb(249, 232, 192)"}} />
                <stop offset="1" style={{stopColor: " rgb(219, 108, 43)"}} />
            </radialGradient>



                {/* {props.playerOfDay && (
                    <g xmlns="http://www.w3.org/2000/svg" style={{stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1}} transform="matrix(0.2959060072898865, 0, 0, 0.2805500030517578, 33.89483213356098, 31.666480571937285)">
                        <path d="M 51.959 13.205 C 55.751 10.22 57.016 5.819 55.751 0 H 34.249 c -2.789 5.694 -2.074 10.264 3.792 13.205 H 51.959 z" style= {{stroke: "none",strokeWidth: 1,strokeDasharray: "none",strokeLinecap: "butt",strokeLinejoin: "miter",strokeMiterlimit: 10, fill: "rgb(233,84,84)" ,fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <polygon points="42.95,30.28 34.25,0 17.75,10.71 25.63,37.49 " style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(237,99,98)",fillRule: "nonzero", opacity: 1}} transform="  matrix(1 0 0 1 0 0) "/>
                        <polygon points="64.37,37.49 72.25,10.71 55.75,0 47.06,30.28 " style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(237,99,98)",fillRule: "nonzero", opacity: 1}}transform="  matrix(1 0 0 1 0 0) "/>
                        <path d="M 45 90 c 16.769 0 30.363 -13.594 30.363 -30.363 S 61.769 29.273 45 29.273 C 34.997 49.991 34.2 70.271 45 90 z" style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(255,204,91)", fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 45 90 c -16.769 0 -30.363 -13.594 -30.363 -30.363 S 28.231 29.273 45 29.273 v 60.696" style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(253,188,75)",fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 37.402 79.821 c -0.124 0 -0.25 -0.023 -0.372 -0.071 c -5.372 -2.129 -9.594 -6.223 -11.887 -11.527 c -2.293 -5.305 -2.384 -11.185 -0.255 -16.557 c 0.206 -0.52 0.793 -0.773 1.314 -0.568 c 0.519 0.206 0.774 0.794 0.568 1.314 c -1.93 4.87 -1.848 10.2 0.231 15.008 c 2.079 4.809 5.905 8.519 10.774 10.448 c 0.52 0.206 0.774 0.794 0.568 1.314 C 38.186 79.579 37.805 79.821 37.402 79.821 z" style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(236,153,34)",fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 64.172 68.247 c -0.125 0 -0.25 -0.023 -0.373 -0.071 c -0.52 -0.206 -0.774 -0.794 -0.568 -1.314 c 3.983 -10.052 -0.954 -21.471 -11.006 -25.455 c -0.52 -0.206 -0.774 -0.794 -0.568 -1.314 c 0.206 -0.52 0.794 -0.773 1.314 -0.568 c 11.09 4.395 16.537 16.993 12.142 28.083 C 64.956 68.005 64.574 68.247 64.172 68.247 z" style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(236,153,34)",fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                        <path d="M 40.62 72.273 c -0.567 0 -1.132 -0.233 -1.547 -0.69 l -6.517 -7.177 c -0.351 -0.386 -0.543 -0.885 -0.543 -1.405 v -4.728 c 0 -0.871 0.525 -1.637 1.338 -1.95 c 0.813 -0.312 1.716 -0.101 2.301 0.545 l 4.902 5.397 c 0.034 0.036 0.09 0.04 0.127 0.008 l 13.854 -11.902 c 0.626 -0.539 1.485 -0.661 2.234 -0.313 c 0.752 0.345 1.219 1.073 1.219 1.9 v 5.096 c 0 0.61 -0.266 1.188 -0.729 1.586 L 41.984 71.764 C 41.586 72.105 41.102 72.273 40.62 72.273 z" style={{stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill:"rgb(236,153,34)",fillRule: "nonzero", opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    </g>
                )} */}
            </svg>
        </div>
        </Badge>
    )
}

export default ShirtComponent