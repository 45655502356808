import styled from "@emotion/styled";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Rating, Skeleton, TextField, Typography } from "@mui/material";
import { LineUpItem, Team, TeamPlayer, TeamPlayerPosition, TeamPlayerStatisticsModel } from "../../types/Saison";
import HttpService from "../../services/HttpService";
import React from "react";
import { Fade } from "react-awesome-reveal";
import DialogProgressComponent from "../common/DialogProgressComponent";

function TeamPlayerStatisticsDialogComponent(props: {
    open:boolean,
    onClose: () => void;
    teamPlayerId?:string,
    lineUpItem?:LineUpItem
}) {

    const { open, onClose } = props;
    const [ model, setModel ] = React.useState<TeamPlayerStatisticsModel>();
    const [ loaded, setLoaded ] = React.useState(false);

    React.useEffect(function() {
        setModel(undefined);
        HttpService.get('/SaisonAPI/TeamPlayerDetails', {
            params: {
                teamPlayerId: props.teamPlayerId,
                lineUpItemId: props.lineUpItem?.id        
            }
        }
        ).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            }       
            setModel(res.data);
        });       
    }, [props.teamPlayerId, props.lineUpItem])

    const handleClose = () => {
        onClose();
    }

    return (
        <TeamPlayerDialog open={open} fullWidth={true}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                {props.teamPlayerId && (
                    <Box className='teamlogo-fit' minWidth={40} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + model?.teamPlayerStatistics.imageLogoPath + ')'}} />
                )}
                <Box marginLeft={2}>{model?.teamPlayerStatistics.name} {model?.teamPlayerStatistics.number ? '(' + model.teamPlayerStatistics.number + ')' : ''}</Box>
                <Rating
                    sx={{position: 'absolute', right: '1.5rem'}}
                    value={6 - (model?.teamPlayerStatistics && model?.teamPlayerStatistics.avgGrade ? model?.teamPlayerStatistics.avgGrade : 6)}
                    readOnly={true}
                    precision={0.1}
                    />
            </DialogTitle>
            {/* {!model && (
                <DialogProgressComponent delay={1000} />
                    // <Box textAlign={'center'} paddingLeft={'50px'}>
                    // <Skeleton variant="rectangular" width={'90%'} height={30} animation="wave"/>
                    // </Box>
            )}  */}
            <DialogContent dividers>
                {!model && (
                    <Fade duration={500}>
                        <Skeleton variant="rounded" width={'20%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" width={'30%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        {props.lineUpItem && (
                            <Box>
                                <Skeleton variant="rounded" width={'15%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                                <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                                <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                            </Box>
                        )}
                    </Fade>
                )}
                {model && !model.teamPlayerStatistics && (
                    <Box width={'100%'} textAlign={'center'}>KEINE STATISTIKEN VORHANDEN</Box>
                )} 
                {model && model.teamPlayerStatistics && (
                    <Box>
                        <Typography variant="subtitle1" marginBottom={'10px'}>ALLGEMEIN</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="POSITION"
                            value={
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.goalkeeper ? 'TOR' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.defender ? 'ABWEHR' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.midfielder ? 'MITTELFELD' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.striker ? 'STURM' : ''
                            }
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="PREIS"
                            value={model?.teamPlayerStatistics.price}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="PREIS (KICKER)"
                            value={model?.teamPlayerStatistics.kickerPrice}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="MANAGER"
                            value={model?.teamPlayerStatistics.userName}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <Typography variant="subtitle1" marginBottom={'10px'}>STATISTIKEN</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="EINSÄTZE"
                            value={model?.teamPlayerStatistics.sumSpieltage}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="NOTE DURCHSCHNITT"
                            value={model?.teamPlayerStatistics.avgGrade.toFixed(2)}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="TORE"
                            value={model?.teamPlayerStatistics.sumGoals}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="VORARBEITEN"
                            value={model?.teamPlayerStatistics.sumAssists}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </Box>
                )} 
                {model && model.lineUpItem && (
                    <Box>
                        <Typography variant="subtitle1" marginBottom={'10px'}>SPIELTAG</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="TORE"
                            value={model?.lineUpItem.goals}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="VORARBEITEN"
                            value={model?.lineUpItem.assists}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>SCHLIESSEN</Button>
            </DialogActions>
        </TeamPlayerDialog>
    )
}

export default TeamPlayerStatisticsDialogComponent

const TeamPlayerDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))