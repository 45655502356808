import { Add, Delete, Edit, ListAlt, LockOpen, Lock, CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Fab, Fade, IconButton, List, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import Loader from "../components/common/Loader";
import HttpService from "../services/HttpService";
import { AdministrationModel, AllocRole, AllocState, SaisonEvent, SaisonState, Team, UserSaisonAlloc } from "../types/Saison";
import TeamSuggestSelectionDialogComponent from "../components/common/TeamPlayerSuggestSelectionDialogComponent";
import ConformationDialog from "../components/common/ConfirmationDialog";
import AddManagerDialogComponent from "../components/saison/AddManagerDialogComponent";
import EditManagerDialogComponent from "../components/saison/EditManagerDialogComponent";
import EditSettingsDialogComponent from "../components/saison/EditSettingsDialogComponent";
import EditEventDialogComponent from "../components/saison/EditEventDialogComponent";
import moment from "moment";

const AdministrationPage = () => {
    let { id } = useParams();

    const [ administrationModel, setAdministrationModel ] = React.useState<AdministrationModel>(); 
    const [ openTeamSuggestSelectionDialog, setOpenTeamSuggestSelectionDialog ] = React.useState(false);
    const [ openConfirmDeleteTeamDialog, setOpenConfirmDeleteTeamDialog ] = React.useState(false);
    const [ openEditManagerDialog, setOpenEditManagerDialog ] = React.useState(false);
    const [ openEditSettingsDialog, setOpenEditSettingsDialog ] = React.useState(false);
    const [ openEditEventDialog, setOpenEditEventDialog ] = React.useState(false);
    const [ editUserSaisonAlloc, setEditUserSaisonAlloc ] = React.useState<UserSaisonAlloc>();
    const [ openAddManagerDialog, setOpenAddManagerDialog] = React.useState(false);
    const [ teamToDelete, setTeamToDelete] = React.useState<Team>()
    const [ eventToEdit, setEventToEdit ] = React.useState<SaisonEvent>()
    const [ reducerValue, forceUpdate ] = React.useReducer(x => x + 1, 0);

    const ctx = React.useContext(AppContext)

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/SaisonAPI/Administration', {
            params: { saisonId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)
                    
                setAdministrationModel(res.data);
                
            });    
    }, [id, reducerValue])

    const handleAddTeam = () => {
        setOpenTeamSuggestSelectionDialog(true)
    }

    const handleTeamSuggestSelectionDialogSaved = () => {
        setOpenTeamSuggestSelectionDialog(false)
        forceUpdate()
    }

    const handleAddManager = () => {
        setOpenAddManagerDialog(true);
    }

    const handleAddManagerDialogOk = () => {
        setOpenAddManagerDialog(false);
        forceUpdate()
    }

    const handleAddManagerDialogAbort = () => {
        setOpenAddManagerDialog(false);
    }

    const handleEditManager = (userSaisonAlloc: UserSaisonAlloc) => {
        setEditUserSaisonAlloc(userSaisonAlloc);
        setOpenEditManagerDialog(true);    
    }

    const handleEditManagerAbort = () => {
        setOpenEditManagerDialog(false);
    }

    const handleEditManagerOk = () => {
        setOpenEditManagerDialog(false);
        forceUpdate();
    }

    const handleEditSettings = () => {
        setOpenEditSettingsDialog(true)
    }

    const handleEditSettingsAbort = () => {
        setOpenEditSettingsDialog(false)
    }

    const handleEditSettingsOk = () => {
        setOpenEditSettingsDialog(false)
        forceUpdate()
    }

    const handleDeleteTeam = () => {

        HttpService.delete('/SaisonAPI/Team', {
            params: { teamId: teamToDelete?.id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
                setOpenConfirmDeleteTeamDialog(false);
                forceUpdate();
            });  
    }

    const handleEditEventOk = () => {
        setOpenEditEventDialog(false);
        forceUpdate()
    }

    const handleCreateNewEvent = () => {
        setEventToEdit(undefined)
        setOpenEditEventDialog(true)
    }

    const handleEditEvent = (Event:SaisonEvent) => {
        setEventToEdit(Event)
        setOpenEditEventDialog(true)
    }

    return (
        <div>
            {administrationModel && (
                <Fade in>
                    <div>
                        <Card sx={{marginBottom: 2}}>
                            <CardHeader title="MANAGER" action={
                                administrationModel.saison.state !== SaisonState.Finished && (
                                <Box>
                                    <IconButton onClick={handleAddManager} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                        <Add />
                                    </IconButton>                                   
                                </Box>
                                )
                            }>
                            </CardHeader>
                            <CardContent>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NAME</TableCell>
                                                <TableCell>ROLLE</TableCell>
                                                <TableCell>STATUS</TableCell>
                                                <TableCell align="center">...</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {administrationModel.userSaisonAllocs.sort((a,b) => a.playerName.localeCompare(b.playerName)).map((UserSaisonAlloc) => (
                                                <TableRow 
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                    key={UserSaisonAlloc.id}
                                                    >
                                                    <TableCell>{UserSaisonAlloc.playerName}</TableCell>
                                                    <TableCell>
                                                        {UserSaisonAlloc.allocRole === AllocRole.guest && (
                                                            <Box>GAST</Box>
                                                        )}
                                                        {UserSaisonAlloc.allocRole === AllocRole.member && (
                                                            <Box>SPIELER</Box>
                                                        )}
                                                        {UserSaisonAlloc.allocRole === AllocRole.owner && (
                                                            <Box>SPIELER (ADMIN)</Box>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {UserSaisonAlloc.allocState === AllocState.Active && (
                                                            <Box>AKTIV</Box>
                                                        )}
                                                        {UserSaisonAlloc.allocState === AllocState.Invited && (
                                                            <Box>EINGELADEN</Box>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        
                                                        <Button onClick={() => handleEditManager(UserSaisonAlloc)} disabled={UserSaisonAlloc.allocRole === AllocRole.owner && UserSaisonAlloc.id !== administrationModel.userSaisonAlloc.id }>
                                                            <Edit/>
                                                        </Button>                                                     
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        <Card sx={{marginBottom: 2}}>
                            <CardHeader title="VEREINE" action={
                                administrationModel.saison.state !== SaisonState.Finished && (
                                <Box>
                                    <IconButton onClick={handleAddTeam} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                        <ListAlt />
                                    </IconButton>
                                </Box>
                                )
                            }>
                            </CardHeader>
                            <CardContent>
                                <TableContainer>
                                <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>NAME</TableCell>
                                                <TableCell>NAME (KURZ)</TableCell>
                                                {administrationModel.saison.state !== SaisonState.Finished && (
                                                    <TableCell align="center">...</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {administrationModel.teams.map((Team) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}} 
                                                    key={Team.id}
                                                    >
                                                    <TableCell>
                                                        <Box className='teamlogo-fit' minWidth={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + Team.imageLogoPath + ')'}} />
                                                    </TableCell>
                                                    <TableCell>{Team.name}</TableCell>
                                                    <TableCell>{Team.shortName}</TableCell>
                                                    {administrationModel.saison.state !== SaisonState.Finished && (
                                                    <TableCell align="center">
                                                        <Button onClick={() => {
                                                            setOpenConfirmDeleteTeamDialog(true) 
                                                            setTeamToDelete(Team)
                                                            }}>
                                                            <Delete />
                                                        </Button>
                                                    </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                        <Card sx={{marginBottom: 2}}>
                            <CardHeader title="EINSTELLUNGEN" action={
                            <Box>
                                <IconButton onClick={handleEditSettings} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                    <Edit />
                                </IconButton>
                            </Box>
                            }></CardHeader>
                            <CardContent>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Eigenschaft</TableCell>
                                            <TableCell>Einstellung</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell>
                                                {administrationModel.saison.state === 0 && (
                                                    <LockOpen />
                                                )}
                                                {administrationModel.saison.state > 0 && (
                                                    <Lock />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                                            <TableCell>Tippspiel aktiv?</TableCell>
                                            <TableCell>{administrationModel.saison.tippspiel ? <CheckBox /> : <CheckBoxOutlineBlankOutlined/>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title="TERMINE" action={
                                administrationModel.saison.state !== SaisonState.Finished && (
                                <Box>
                                    <IconButton onClick={handleCreateNewEvent} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                        <Add />
                                    </IconButton>                                   
                                </Box>
                                )
                            }>
                            </CardHeader>
                            <CardContent>
                                <Table  size="small">
                                    <TableBody>
                                        {administrationModel.events.map((Event) => (
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }}} 
                                                key={Event.id}
                                                >
                                                <TableCell>{moment(Event.startDate).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{Event.title}</TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => {
                                                        handleEditEvent(Event)
                                                        }}>
                                                        <Edit />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>                          
                        <TeamSuggestSelectionDialogComponent 
                            open={openTeamSuggestSelectionDialog} 
                            onClose={() => setOpenTeamSuggestSelectionDialog(false)} 
                            onSave={handleTeamSuggestSelectionDialogSaved}
                            Teams={administrationModel.teams}
                            saisonId={id}
                        />
                        <ConformationDialog 
                            open={openConfirmDeleteTeamDialog}
                            onCancel={() => setOpenConfirmDeleteTeamDialog(false)}
                            onNo={() => setOpenConfirmDeleteTeamDialog(false)}
                            onYes={handleDeleteTeam}
                            title="LÖSCHEN?"
                            children={"Möchten Sie den Verein wirklich löschen?"}
                            />
                        <AddManagerDialogComponent 
                            open={openAddManagerDialog}
                            onAbort={handleAddManagerDialogAbort}
                            onOk={handleAddManagerDialogOk}
                            saisonId={administrationModel.saison.id}
                        />
                        <EditManagerDialogComponent 
                            userSaisonAlloc={editUserSaisonAlloc}
                            open={openEditManagerDialog}
                            onAbort={handleEditManagerAbort}
                            onOk={handleEditManagerOk}
                        />
                        <EditSettingsDialogComponent
                            onAbort={handleEditSettingsAbort}
                            onOk={handleEditSettingsOk}
                            open={openEditSettingsDialog}
                            saison={administrationModel.saison}
                            userSaisonAllocs={administrationModel.userSaisonAllocs}
                        />
                        <EditEventDialogComponent
                            onAbort={() => setOpenEditEventDialog(false)}
                            onOk={handleEditEventOk}
                            open={openEditEventDialog}
                            saisonId={administrationModel.saison.id}
                            event={eventToEdit}
                        />
                        <Fab color="primary" aria-label="add" size="small" onClick={handleEditSettings} sx={{position: 'fixed', bottom: '16px', right: '16px'}}>
                            <Edit />
                        </Fab>
                    </div>
                </Fade>
            )}
            {!administrationModel && (
                <Loader delay={1500} />
            )}
        </div>
    )
}
export default AdministrationPage