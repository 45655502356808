import styled from "@emotion/styled"
import { CheckBox } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputLabel, Slider } from "@mui/material"
import React from "react"
import { useCookies } from 'react-cookie';
import HttpService from "../../services/HttpService";
import { Account } from "../../types/Common";

const ChangeSizeDialogComponent = (props: {

    account:Account
    open:boolean
    onClose: () => void

    }) => {

    const [zoomValue, setZoomValue] = React.useState(100)
    const [zoomCookie, setZoomCookie] = useCookies(['zoom']);
    const [account, setAccount] = React.useState(props.account);
    const [showActualMatchDay, setShowActualMatchDay] = React.useState(props.account.showActualMatchDay);

    React.useEffect(function() {
        if(zoomCookie.zoom) 
            setZoomValue(zoomCookie.zoom)
    }, [])


    const handleScale = (event:any) => {
        const scale = parseFloat(event.target.value)
        document.body.style.cssText = document.body.style.cssText + "; zoom: " + scale + "%";
        // Geht auch. Dann müssen aber die Schriftgrößen-Einheiten der betroffenden Texte auf 'em' gesetzt werden.
        //document.body.style.cssText = document.body.style.cssText + "; font-size: " + scale + "%";
        setZoomCookie('zoom', scale, { path: '/' })
        setZoomValue(scale)
    }

    const reset = () => {
        document.body.style.cssText = document.body.style.cssText + "; zoom: " + 100 + "%";
        setZoomCookie('zoom', 100, { path: '/' })
        setZoomValue(100)
    }

    const handleSave = () => {
        var formData = new FormData();

        formData.append("id", account.id)
        formData.append("showActualMatchDay", JSON.stringify(showActualMatchDay))

        HttpService.post('/AccountAPI/Account', 
        formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }}
        ).then(res => {
            account.showActualMatchDay = showActualMatchDay
            props.onClose();
        });
    }

    const handleClose = () => {
        props.onClose()
    }

    return (
        <ChangeSizeDialog open={props.open}>
            <DialogTitle display={'flex'} flexDirection={'row'}>
                Einstellungen
            </DialogTitle>
            <DialogContent>
                <Box>
                    <InputLabel>Zoom | {zoomValue}%</InputLabel>
                    <Slider placeholder="" name="Zoom" min={100} max={150} step={1} value={zoomValue} aria-label="Default" valueLabelDisplay="auto" onChange={handleScale} />
                    <FormControlLabel control={<Checkbox checked={showActualMatchDay} onClick={() => showActualMatchDay ? setShowActualMatchDay(false) : setShowActualMatchDay(true)}/>} label="Aktuellen Spieltag öffnen?" />
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={reset}>ZURÜCKSETZEN</Button> */}
                <Button onClick={handleClose}>SCHLIESSEN</Button>
                <Button onClick={handleSave}>ÜBERNEHMEN</Button>
            </DialogActions>
        </ChangeSizeDialog>
    )
}
export default ChangeSizeDialogComponent

const ChangeSizeDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))