import { Box, Grid, Typography } from "@mui/material"
import HttpService from "../../services/HttpService"
import { MatchDayMatch, MatchDayTeam } from "../../types/MatchDay"
import { Team } from "../../types/Saison"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import ImageComponent from "../common/ImageComponent"

export default function MatchDayMatchComponent(props: {
        matchDayMatch: MatchDayMatch | undefined, 
        matchDayTeam1:MatchDayTeam | undefined, 
        team1:Team | undefined, 
        serverDateTime:string,
        matchDayTeam2:MatchDayTeam | undefined, 
        team2:Team | undefined
        marginBottom:string | undefined
    }) {
    
    const navigate = useNavigate();

    function handleMatchClick() {
        navigate('/match/' + props.matchDayMatch?.id)
    }

    return (
        <Grid container marginBottom={props.marginBottom} sx={{cursor: 'pointer'}} onClick={handleMatchClick}>
            <Grid item xs={true} textAlign='right' paddingRight='10px' paddingTop='4px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                <Typography sx={{ display: { xs: 'none', sm: 'block' } }} whiteSpace='nowrap' overflow='hidden' fontWeight='bold' textOverflow='ellipsis'>{props.team1?.name}</Typography>
                <Typography sx={{ display: { xs: 'block', sm: 'none' } }} fontWeight='bold'>{props.team1?.shortName}</Typography>
            </Grid>
            <Grid item xs='auto' minWidth='50px' paddingRight='5px' alignSelf={'center'}>
                {/* <Box className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.team1?.imageLogoPath + ')'}}>&nbsp;
                </Box> */}
                <ImageComponent 
                    height={'100%'}
                    width={'100%'}
                    sx={{height: '32px', width: '50px', justifyContent: 'center'}}
                    fileName={'/images/upload/teams/' + props.team1?.imageLogoPath}
                    base64Thumb={props.team1?.logoBase64} 
                    />
            </Grid>
            <Grid item /*xs={4}*/ /*sm={2}*/ xs='auto' sx={{textAlign: '-webkit-center'}}>
                <Box>
                    <Box textAlign='center' paddingTop='2px' paddingLeft='10px' paddingRight='10px' sx={{
                        backgroundColor: '#000000', 
                        borderRadius: '5px',
                        color: '#CCCCCC',
                        width: '80px',
                        height: '32px',
                        alignItems: 'center',
                        justifyContent: 'center'
                        
                        }}>
                        <Grid container>
                            {moment.duration(moment(props.matchDayMatch?.matchDate).diff(moment(props.serverDateTime.split(".")[0]))).asMinutes() > 0 &&
                                moment.duration(moment(props.matchDayMatch?.matchDate).diff(moment(props.serverDateTime.split(".")[0]))).asMinutes() < 60 && (
                                <Grid container>
                                    <Typography fontSize='medium' fontWeight='bold' lineHeight='1.9' className="goal-countdown" sx={{width: '100%'}}>
                                        {Math.ceil(moment.duration(moment(props.matchDayMatch?.matchDate).diff(moment(props.serverDateTime.split(".")[0]))).asMinutes()) + " Min"}
                                    </Typography>
                                </Grid>
                            )}
                            {(moment.duration(moment(props.matchDayMatch?.matchDate).diff(moment())).asMinutes() < 0 || 
                             moment.duration(moment(props.matchDayMatch?.matchDate).diff(moment())).asMinutes() >= 60) && (
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Typography fontSize='large' fontWeight='bold' lineHeight='1.6' textAlign="right" paddingRight={'4px'} className={moment(props.matchDayTeam2?.goalsConcededOldChangedOn).add(5, 'minutes') > moment(props.serverDateTime.split(".")[0]) ? "goal-blink" : ""}>
                                            {moment(props.matchDayMatch?.matchDate).isAfter(moment(props.serverDateTime.split(".")[0])) ? '-' :  props.matchDayTeam2?.goalsConceded}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography fontSize='large' fontWeight='bold' lineHeight='1.6'>
                                        :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography fontSize='large' fontWeight='bold' lineHeight='1.6' textAlign="left" paddingLeft={'4px'} className={moment(props.matchDayTeam1?.goalsConcededOldChangedOn).add(5, 'minutes') > moment(props.serverDateTime.split(".")[0]) ? "goal-blink" : ""}>
                                            {moment(props.matchDayMatch?.matchDate).isAfter(moment(props.serverDateTime.split(".")[0])) ? '-' : props.matchDayTeam1?.goalsConceded}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {/* <Typography fontSize='large' fontWeight='bold' lineHeight='1.6'>
                            {moment(props.matchDayMatch.matchDate) >= moment() ? '- : -' : props.matchDayTeam2?.goalsConceded + ' : ' + props.matchDayTeam1?.goalsConceded}
                        </Typography> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs='auto' minWidth='50px' paddingLeft='5px' alignSelf={'center'}>
                {/* <Box className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.team2?.imageLogoPath + ')'}}>&nbsp;
                </Box> */}
                <ImageComponent 
                    height={'100%'}
                    width={'100%'}
                    sx={{height: '32px', width: '50px', justifyContent: 'center'}}
                    fileName={'/images/upload/teams/' + props.team2?.imageLogoPath}
                    base64Thumb={props.team2?.logoBase64} 
                    />
            </Grid>
            <Grid item xs={true} textAlign='left' paddingLeft='10px' paddingTop='4px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                <Typography sx={{ display: { xs: 'none', sm: 'block' } }} fontWeight='bold' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>{props.team2?.name}</Typography>
                <Typography sx={{ display: { xs: 'block', sm: 'none' } }} fontWeight='bold'>{props.team2?.shortName}</Typography>
            </Grid>
        </Grid>
  )
}