import React from "react";
import { AllocRole, AllocState, UserSaisonAlloc } from "../../types/Saison";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";
import HttpService from "../../services/HttpService";
import { Fade } from "react-awesome-reveal";
import DialogProgressComponent from "../common/DialogProgressComponent";

export interface Props {
    open: boolean;
    userSaisonAlloc?: UserSaisonAlloc,
    onOk: () => void;
    onAbort: () => void;
}

function EditManagerDialogComponent(props: Props) {
    const { open, onOk, onAbort, userSaisonAlloc } = props;

    const [ state, setState ] = React.useState<AllocState>();
    const [ role, setRole ] = React.useState<AllocRole>();

    const [ loading, setLoading ] = React.useState(false);

    React.useEffect(() => {
        if(props.userSaisonAlloc) {
            setState(props.userSaisonAlloc.allocState);
            setRole(props.userSaisonAlloc.allocRole);
        } else {
            reset();
        }
    },[props.userSaisonAlloc])

    const handleOk = () => {
        setLoading(true);
        HttpService.post("/SaisonAPI/Manager", {
            id: props.userSaisonAlloc?.id,
            saisonId: props.userSaisonAlloc?.saisonId,
            allocRole: role,
            allocState: state
          }).then(res => {
            if(res.status === 200) {
                onOk();
                setLoading(false);
            } 
          })
    }

    const handleAbort = () => {
        onAbort();
    }

    const reset = () => {
        setState(AllocState.Active)
        setRole(AllocRole.guest)
    }

    return(

        <Dialog fullWidth={true} open={open}>
            <DialogTitle>{props.userSaisonAlloc?.playerName}</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>
                <FormControl sx={{marginTop: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">ROLLE</InputLabel>
                    <Select fullWidth={true} value={role} size="small" label="ROLLE" onChange={e => setRole(e.target.value as AllocRole)}>
                        <MenuItem value={AllocRole.guest}>GAST</MenuItem>
                        <MenuItem value={AllocRole.member}>SPIELER</MenuItem>
                        <MenuItem value={AllocRole.owner}>SPIELER (ADMIN)</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{marginTop: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">STATUS</InputLabel>
                    <Select fullWidth={true} value={state} size="small" label="STATUS" onChange={e => setState(e.target.value as AllocState)}>
                        <MenuItem value={AllocState.Active}>AKTIV</MenuItem>
                        <MenuItem value={AllocState.Invited}>EINGELADEN</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAbort} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </Dialog>

    )

}

export default EditManagerDialogComponent